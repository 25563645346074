import TableIcon from '@mui/icons-material/GridOnOutlined';
import { Box, Card, CircularProgress, IconButton, Toolbar, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';
import { KaeplaPerspective } from '@kaepla/types';

import { updateDefaultPerspective } from '../../../../../services/firestore/updateProjectDefaultPerspective.js';
import { dataViewState } from '../../../../../services/recoil/nonpersistent/dataViewState.js';
import { perspectiveState } from '../../../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../../../services/recoil/nonpersistent/projectState.js';
import { simulationState } from '../../../../../services/recoil/nonpersistent/simulationState.js';
import { snapShotState } from '../../../../../services/recoil/nonpersistent/snapshotState.js';
import { targetsState } from '../../../../../services/recoil/nonpersistent/targetsState.js';
import { timeSeriesState } from '../../../../../services/recoil/nonpersistent/timeSeriesState.js';
import { KaeplaDataView } from '../../../../../typings/KaeplaDataView.js';
import { snapshotColor } from '../../../../Theme/colors.js';
import { BQJobInfo } from '../../../../features/BQJobInfo.js';
import { CardContentMinPadding } from '../../../../features/CardContentMinPadding.js';
import { InlineEdit } from '../../../../features/InlineEdit.js';
import {
  matrixDataColor,
  matrixDataColorLight,
  simulationDataColor,
  simulationDataColorLight,
  snapshotDataColorLight,
  targetsDataColor,
  targetsDataColorLight,
} from '../../../defaults';
import { chartTitleFromPerspective } from '../../helpers/chartTitleFromPerspective.js';

import { TimeSliceSwitcher } from './TimeSliceSwitcher.js';
import { DataTimelineChart } from './DataTimelineChart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Options {
  disableSliceSwitcher?: boolean;
  simulationPreview?: boolean;
}

export const DataTimeline = ({ disableSliceSwitcher, simulationPreview }: Options) => {
  const [project, setProject] = useRecoilState(projectState);
  const perspective = useRecoilValue(perspectiveState);
  const setDataView = useSetRecoilState(dataViewState);
  const simulation = useRecoilValue(simulationState);
  const snapshot = useRecoilValue(snapShotState);
  const targets = useRecoilValue(targetsState);
  const timeSeries = useRecoilValue(timeSeriesState);
  const [loading, setLoading] = useState(false);
  const changeAggregationOverTimeName = useDebouncedCallback((value: string) => {
    const perspectiveUpdate: Partial<KaeplaPerspective> = {
      ...perspective,
      aggregationOverTimeName: value,
    };
    void updateDefaultPerspective({
      project,
      perspective: perspectiveUpdate,
      setProject,
    });
  }, 500);

  return (
    <Card sx={{ minHeight: 320, height: '100%', position: 'relative' }}>
      <CardContentMinPadding sx={{ pt: 1.1 }}>
        <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
          <InlineEdit
            value={perspective?.aggregationOverTimeName ?? ''}
            callback={changeAggregationOverTimeName}
          />
          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
            {timeSeries?.job && <BQJobInfo job={timeSeries.job} />}
          </Typography>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <Box component="div" color="inherit" sx={{ whiteSpace: 'nowrap' }}>
            <IconButton
              size="small"
              data-testid="user-dialog-close"
              color="primary"
              onClick={() => {
                setDataView(KaeplaDataView.Grid);
              }}
            >
              <TableIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box component="div" color="inherit" sx={{ whiteSpace: 'nowrap' }}>
            {!disableSliceSwitcher && <TimeSliceSwitcher setLoading={setLoading} />}
          </Box>
        </Toolbar>
        <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
            <Box component="span" sx={{ fontSize: '80%', fontWeight: '100', mr: 1 }}>
              {perspective && chartTitleFromPerspective(perspective)}
            </Box>
          </Typography>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        </Toolbar>
        <DataTimelineChart simulationPreview={simulationPreview} loading={loading} />
        <Box fontSize={12} pt={1}>
          {(simulation?.id ?? snapshot?.id ?? targets) && (
            <Box component="span">
              <Box
                component="span"
                display="inline-block"
                border={1}
                borderRadius={6}
                width={12}
                height={12}
                bgcolor={matrixDataColorLight}
                borderColor={matrixDataColor}
                mr={1}
              />
              Original Data
            </Box>
          )}
          {simulation?.id && (
            <Box component="span">
              <Box
                component="span"
                display="inline-block"
                border={1}
                borderRadius={6}
                width={12}
                height={12}
                bgcolor={simulationDataColorLight}
                borderColor={simulationDataColor}
                ml={2}
                mr={1}
              />
              Simulation ({simulation?.name})
            </Box>
          )}
          {snapshot?.id && (
            <Box component="span">
              <Box
                component="span"
                display="inline-block"
                border={1}
                borderRadius={6}
                width={12}
                height={12}
                bgcolor={snapshotDataColorLight}
                borderColor={snapshotColor}
                ml={2}
                mr={1}
              />
              Snapshot ({snapshot.createdAt.toDate().toDateString()})
            </Box>
          )}
          {targets && (
            <Box component="span">
              <Box
                component="span"
                display="inline-block"
                border={1}
                borderRadius={6}
                width={12}
                height={12}
                bgcolor={targetsDataColorLight}
                borderColor={targetsDataColor}
                ml={2}
                mr={1}
              />
              Yearly Targets ({perspective.valueDimension})
            </Box>
          )}
        </Box>
      </CardContentMinPadding>
      {loading && (
        <CircularProgress size={15} sx={{ position: 'absolute', right: 10, bottom: 10 }} />
      )}
    </Card>
  );
};

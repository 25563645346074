import { Box } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-enterprise';

import { formatNumber } from '../helpers/formatNumber';

interface CompareValueCellRendererParameters extends ICellRendererParams {
  color: string;
  isCurrency: boolean;
}

const formatCurrency = (value: number, isCurrency: boolean) => {
  if (!isCurrency) return formatNumber(value);
  return `€ ${formatNumber(value)}`;
};

const roundEpsilon = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

// const relativePercentageDifference = (a: number, b: number) =>
//   Math.abs(((a - b) / ((a + b) / 2)) * 100);

const percentageChange = (a: number, b: number) => (b / a) * 100 - 100;

export const CompareValuesCellRenderer = (properties: CompareValueCellRendererParameters) => {
  const value = properties.value as number;
  const isCurrency = properties.isCurrency;
  if (!properties.column || !properties.data) {
    return <Box component="span">{formatCurrency(value, isCurrency)}</Box>;
  }
  const data = properties.data as Record<string, unknown>;
  const columnId = properties.column.getColId();
  let compareColumnId = `${columnId}__Simulated`;
  const firstValue = (data[columnId] as number) || 0;
  let secondValue = (data[compareColumnId] as number) || 0;
  if (!data[compareColumnId]) {
    compareColumnId = `${columnId}__Precalculated`;
    secondValue = (data[compareColumnId] as number) || 0;
  }
  const color = properties.color;
  // const gap = secondValue / (firstValue / 100) - 100;
  let gap = 0;
  let gapAbsolute = 0;
  if (firstValue !== secondValue) {
    gap = percentageChange(firstValue, secondValue);
    gapAbsolute = Math.abs(
      Number.parseFloat(firstValue.toFixed(4)) - Number.parseFloat(secondValue.toFixed(4)),
    );
  }

  // console.log('---');
  // console.log('firstValue', firstValue);
  // console.log('secondValue', data[compareColumnId], secondValue);
  // console.log('gap', gap);
  // when there's nothing to compare with (compare value is undefined) OR the values are identical, we just chow the original value
  // when the compare value is 0 or null and there is a gap there IS a change e.g. priority shift
  if (data[compareColumnId] === undefined || gapAbsolute < 0.001) {
    return <Box component="span">{formatCurrency(value, isCurrency)}</Box>;
  }
  // TODO: see how we deal with those rounding errors
  // if (gapAbsolute >= 0.001) {
  //   return (
  //     <Box component="span">
  //       <Box component="span" sx={{ color: 'orange', fontSize: 12, ml: 1 }}>
  //         ⚠
  //       </Box>{' '}
  //       {formatCurrency(value, isCurrency)}
  //     </Box>
  //   );
  // }
  return (
    <Box sx={{ lineHeight: 'normal', mt: 0.3 }}>
      <Box component="span">{formatCurrency(firstValue, isCurrency)}</Box>
      <br />
      <Box component="span" sx={{ color: gap > 0 ? 'green' : 'red', fontSize: 12, ml: 1 }}>
        {roundEpsilon(gap).toString()} % · {formatCurrency(gapAbsolute, isCurrency)}
      </Box>
      <Box component="span" sx={{ color, fontSize: 12, ml: 1 }}>
        {formatCurrency(secondValue, isCurrency)}
      </Box>
    </Box>
  );
};

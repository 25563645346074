import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { KaeplaPivotParameters } from '@kaepla/types';

import { requestLog } from '../../Frontend/helpers/logger.js';

interface KOptions {
  params: KaeplaPivotParameters;
  uid?: string;
  setError: (error: string) => void;
}

export const getDataForGrid = async ({ params, uid, setError }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const getPivotData = httpsCallable(functions, 'getPivotData');

  requestLog.log(`pivotData.post({body:${JSON.stringify({ ...params, uid })}})`);
  const result = await getPivotData(params).catch((error: Error) => {
    requestLog.warn('Error calling pivotData', error);
    setError(error.toString().replaceAll('FirebaseError: ', ' '));
  });

  if (!result?.data) {
    return [];
  }
  if (params.downloadAsFile) {
    return result.data as Record<string, unknown>;
  }

  return (result.data as Record<string, unknown>).rows;
};

import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { KaeplaEvent } from '@kaepla/types';
import Timeline from '@mui/lab/Timeline';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import {
  Alert,
  Box,
  Divider,
  Grid2 as Grid,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { addFirestoreCollectionListener } from '../../../services/firestore/addFirestoreCollectionListener.js';
import { getEvents } from '../../../services/firestore/getEvents.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState.js';
import { ProjectLoading } from '../../features/ProjectLoading.js';
import { Layout } from '../../Layout/Layout.js';
import { ProjectInfoSmall } from '../Perspectives/features/ProjectSummary/features/ProjectInfoSmall.js';

import { eventListenerDefaultAmount, eventsFetchMore } from './defaults.js';
import { EventGroups } from './EventGroups.js';

export const Events = () => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [events, setEvents] = useState<KaeplaEvent[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData>>();

  const fetchEvents = async () => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    const nextEvents = await getEvents({
      project,
      scopePathStringified: JSON.stringify(currentScopePath),
      lastVisible,
      setLastVisible,
      more: eventsFetchMore,
    });

    if (nextEvents.length === 0) {
      setHasMore(false);
    }

    setEvents((previousEvents) => [...previousEvents, ...nextEvents]);
  };

  useEffect(() => {
    if (!project?.id) return;
    if (!kaeplaUser?.uid || !currentScopePath) return;

    const fireStorePath = `events`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
      {
        fieldPath: 'scopePathStringified',
        opStr: '==',
        value: JSON.stringify(currentScopePath),
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'dateTime',
        direction: 'desc',
      },
      limit: eventListenerDefaultAmount,
      callback: (data) => {
        const _events = data as KaeplaEvent[];
        const load = async () => {
          setLoading(true);
          await new Promise((resolve) => setTimeout(resolve, 500));
          setEvents((previousEvents) => [
            ..._events.filter((event) => !previousEvents.map((p) => p.id).includes(event.id)),
            ...previousEvents,
          ]);
          setLoading(false);
        };
        void load();
      },
      snapshotCallback: (snapshot) => {
        const _lastVisible = snapshot.docs.at(-1);
        setLastVisible(_lastVisible);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [currentScopePath, kaeplaUser?.uid, project?.id]);

  if (!project?.id) {
    return <ProjectLoading />;
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 4, md: 3 }}>
                <ProjectInfoSmall title={project.name} />
              </Grid>
              <Grid size={2}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid
                size={{ xs: 12, sm: 6, md: 7 }}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <Typography variant="h6">Event Log</Typography>
                {project.currentImportId && (
                  <Alert severity="info">
                    An update is currently in progress, editing currently disabled.
                  </Alert>
                )}
                <Typography variant="body1">View all activities in this project.</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid size={12}>
          <Box
            id="events-scrollable"
            component={Paper}
            sx={{
              p: 2,
              height: window.innerHeight - 400,
              overflow: 'auto',
            }}
          >
            {loading && <LinearProgress />}
            <InfiniteScroll
              dataLength={events.length} // This is important field to render the next data
              next={fetchEvents}
              hasMore={hasMore}
              loader={
                <Box>
                  <Typography variant="body2">
                    Loading...{' '}
                    <Box
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      component="span"
                      onClick={() => {
                        void fetchEvents();
                      }}
                    >
                      click to load
                    </Box>
                  </Typography>
                </Box>
              }
              scrollableTarget="events-scrollable"
            >
              <Timeline
                sx={{
                  p: 0,
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.01,
                  },
                }}
              >
                {events.length > 0 && (
                  <EventGroups events={events} fetchEvents={fetchEvents} hasMore={hasMore} />
                )}
              </Timeline>
            </InfiniteScroll>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

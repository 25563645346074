import { Paper } from '@mui/material';
import numbro from 'numbro';
import { PromptAnswer } from '@kaepla/types';

import { isItNumber } from '../../../AI/lib/isItNumber';

interface Options {
  answer: PromptAnswer;
}
export const AnswerNumber = ({ answer }: Options) => {
  if (!answer.data) return;
  // console.log('answer.data', answer.data, typeof answer.data);

  if (!answer.data) return <Paper sx={{ fontSize: 20, fontWeight: 100, p: 1 }}>n/a</Paper>;
  if (!isItNumber(answer.data as string) && answer?.data) {
    return <Paper sx={{ fontSize: 20, fontWeight: 100, p: 1 }}>{answer.data as string}</Paper>;
  }

  const floatified = (answer.data as number) * 1;
  const numberFormatted = numbro(floatified).format({
    thousandSeparated: true,
    mantissa: 2,
  });
  const averaged = numbro(floatified).format({
    average: true,
    mantissa: 2,
  });

  let output = ` € ${numberFormatted} (${averaged})`;

  if (answer.query.toLowerCase().includes('volume')) {
    output = ` ${numberFormatted} (${averaged})`;
  }

  // TODO: get currency information from backend
  return <Paper sx={{ fontSize: 20, fontWeight: 100, p: 1 }}>{output}</Paper>;
};

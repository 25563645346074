import {
  KaeplaApiParameters,
  KaeplaDataOperation,
  KaeplaEventEffect,
  KaeplaEventType,
  KaeplaFunctionGroup,
  KaeplaQueryType,
  KaeplaTargetsFigure,
  MatrixTimeSlice,
  MatrixTimeseries,
} from '@kaepla/types';
import AddIcon from '@mui/icons-material/Add';
import {
  Divider,
  Fab,
  FormControlLabel,
  Grid2 as Grid,
  Paper,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { getFromKaepla } from '../../../services/api/getFromKaepla.js';
import { createEvent } from '../../../services/firestore/createEvent.js';
import { createNewTargets } from '../../../services/firestore/createNewTargets.js';
import { matrixFilteredState } from '../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { perspectiveState } from '../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { simulationState } from '../../../services/recoil/nonpersistent/simulationState.js';
import { timeSeriesState } from '../../../services/recoil/nonpersistent/timeSeriesState.js';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState.js';
import { Layout } from '../../Layout/Layout.js';
import { ProjectLoading } from '../../features/ProjectLoading.js';
import { ProjectInfoSmall } from '../Perspectives/features/ProjectSummary/features/ProjectInfoSmall.js';

import { TargetsOverview } from './TargetsOverview.js';

export const Targets = () => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  const project = useRecoilValue(projectState);
  const simulation = useRecoilValue(simulationState);
  const perspective = useRecoilValue(perspectiveState);
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const timeseries = useRecoilValue(timeSeriesState);
  const setTimeSeries = useSetRecoilState(timeSeriesState);
  const [showAllTargetsForProject, setShowAllTargetsForProject] = useState(false);

  const handleShowAllTargetsForProject = () => {
    setShowAllTargetsForProject(!showAllTargetsForProject);
  };

  const create = async () => {
    if (!kaeplaUser) return;

    const targetFigures: Partial<KaeplaTargetsFigure>[] = [];
    const dimension = perspective?.valueDimension ?? project.defaultPerspective?.valueDimension;

    const targetsDimension = matrixFiltered?.dimensions?.dimensions.find(
      (d) => d.columnName === dimension,
    );

    timeseries.timeseries.forEach((t) => {
      if (Date.parse(t.date) < Date.now()) return;
      if (t.value > 0) {
        const percentage = Math.random() * 0.2;
        const absoluteValue = t.value * (1 + percentage);
        const figure: Partial<KaeplaTargetsFigure> = {
          validAsOf: t.date,
          originalValue: t.value,
          percentage,
          absoluteValue,
        };
        targetFigures.push(figure);
      }
    });

    const _targets = await createNewTargets({
      project,
      scopePath: currentScopePath,
      createdBy: kaeplaUser.uid,
      targetFigures,
      targetsDimension,
    });

    if (_targets) {
      void createEvent({
        uid: kaeplaUser?.uid,
        eventType: KaeplaEventType.TARGETS_CREATE_TARGETS,
        effect: KaeplaEventEffect.PROJECT_TARGETS_CHANGE,
        functionGroup: KaeplaFunctionGroup.TARGETS,
        operation: KaeplaDataOperation.CREATE,
        project,
        scopePath: currentScopePath,
      });

      setTimeout(() => {
        navigate(`/Targets/${project.id}/${_targets.id}`);
      }, 1000);
    }
  };

  // we need the timeseries in the targets screen
  // so we can create targets based on the timeseries
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    if (!currentScopePath) return;
    const parameters: KaeplaApiParameters = {
      q: 'timeseries' as KaeplaQueryType,
      p: currentScopePath,
      projectId: project.id,
      simulationId: simulation?.id,
      timeSlice: MatrixTimeSlice.year,
      s: 'TimeSliceSwitcher',
    };

    getFromKaepla({
      callBack: (apiResponse) => {
        if (!apiResponse) return;
        const result = apiResponse.response as MatrixTimeseries;
        if (result) {
          setTimeSeries(result);
        }
      },
      params: parameters,
      uid: kaeplaUser?.uid,
    });
  }, [currentScopePath, kaeplaUser?.uid, project?.id, setTimeSeries, simulation?.id]);

  if (!project?.id) {
    return <ProjectLoading />;
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <>
        <Paper sx={{ p: 2 }} data-testid="targets-OverviewHeader">
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 4, lg: 3 }}>
              <ProjectInfoSmall title={project.name} />
            </Grid>
            <Grid size={2}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid
              size={{ xs: 12, sm: 6, md: 7 }}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <Typography variant="h6">Targets</Typography>
              <Typography variant="body1">Create Planning Targets.</Typography>
            </Grid>
          </Grid>
        </Paper>
        <Toolbar disableGutters variant="dense">
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <FormControlLabel
            control={
              <Switch
                checked={showAllTargetsForProject}
                size="small"
                onChange={handleShowAllTargetsForProject}
              />
            }
            label="all"
          />
          <Fab
            color="primary"
            aria-label="add targets"
            data-testid="targets-add"
            sx={{ position: 'fixed', bottom: 20, right: 20 }}
            disabled={
              !!project.currentImportId || !timeseries || timeseries.timeseries.length === 0
            }
            onClick={() => {
              void create();
            }}
          >
            <AddIcon />
          </Fab>
        </Toolbar>
        <TargetsOverview showAllTargetsForProject={showAllTargetsForProject} />
      </>
    </Layout>
  );
};

import SucceededIcon from '@mui/icons-material/CheckCircle';
import FailedIcon from '@mui/icons-material/ErrorOutlineRounded';
import OpenProjectIcon from '@mui/icons-material/OpenInNewOutlined';
import PendingActionsOutlined from '@mui/icons-material/PendingActionsOutlined';
import AdminIcon from '@mui/icons-material/Person2Outlined';
import AutomatedIcon from '@mui/icons-material/ScheduleOutlined';
import { Box, SvgIconProps } from '@mui/material';
import { PropsWithChildren } from 'react';

interface IconProperties extends SvgIconProps {
  name:
    | 'SucceededIcon'
    | 'FailedIcon'
    | 'OpenProjectIcon'
    | 'AdminIcon'
    | 'AutomatedIcon'
    | 'PendingActionsOutlined';
  center?: boolean;
}

function Center(properties: PropsWithChildren<unknown>) {
  return (
    <Box height={'100%'} display={'flex'} alignItems={'center'}>
      {properties.children}
    </Box>
  );
}
function BaseIcon({ name, ...properties }: Omit<IconProperties, 'center'>) {
  switch (name) {
    case 'SucceededIcon': {
      return <SucceededIcon {...properties} />;
    }
    case 'FailedIcon': {
      return <FailedIcon {...properties} />;
    }
    case 'OpenProjectIcon': {
      return <OpenProjectIcon {...properties} />;
    }
    case 'AdminIcon': {
      return <AdminIcon {...properties} />;
    }
    case 'AutomatedIcon': {
      return <AutomatedIcon {...properties} />;
    }
    case 'PendingActionsOutlined': {
      return <PendingActionsOutlined {...properties} />;
    }
    default: {
      return null;
    }
  }
}

export function Icon({ center, name, ...properties }: IconProperties) {
  if (center) {
    return (
      <Center>
        <BaseIcon name={name} {...properties} />
      </Center>
    );
  }

  return <BaseIcon name={name} {...properties} />;
}

import AddIcon from '@mui/icons-material/AddOutlined';
import SamlActiveIcon from '@mui/icons-material/CheckCircle';
import RemoveIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, Typography } from '@mui/material';
import { KaeplaAuthenticationProviderProperties } from '@kaepla/types';

import { useAuth } from '../../../../../AuthReactProvider';
import { requestPasswordResetLink } from '../../../../../services/api/sendPasswordResetLink';

export const AuthProviderActions = ({
  provider,
  isActiveForUser,
  setAuthMessage,
}: KaeplaAuthenticationProviderProperties) => {
  const { removeAuthenticationProvider, addOAuthProvider } = useAuth();

  const removeProvider = () => {
    void removeAuthenticationProvider(provider.providerId);
  };

  const addProvider = () => {
    if (provider.type === 'oAuth2') {
      void addOAuthProvider(provider.providerId);
      return;
    } else if (provider.type === 'password') {
      void requestPasswordResetLink();

      setAuthMessage('Link is on the way!');
      return;
    }
  };

  if (provider.alwaysAvailable && provider.type !== 'saml') {
    return <Typography variant="body2">always available</Typography>;
  }
  if (provider.alwaysAvailable && provider.type === 'saml') {
    return <SamlActiveIcon fontSize="small" color="success" />;
  }
  if (isActiveForUser) {
    return (
      <IconButton
        edge="end"
        aria-label="delete"
        data-testid={`auth-${provider.providerId}-remove`}
        disabled={!provider.active}
        onClick={() => {
          removeProvider();
        }}
      >
        <RemoveIcon />
      </IconButton>
    );
  }
  return (
    <IconButton
      edge="end"
      aria-label="add"
      data-testid={`auth-${provider.providerId}-add`}
      disabled={!provider.active || provider.alwaysAvailable}
      onClick={() => {
        addProvider();
      }}
    >
      <AddIcon />
    </IconButton>
  );
};

import { KaeplaDataOperation, KaeplaEventType, KaeplaFunctionGroup } from '@kaepla/types';
import AddIcon from '@mui/icons-material/Add';
import {
  Divider,
  Fab,
  FormControlLabel,
  Grid2 as Grid,
  Paper,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { useUserPreferences } from '../../../UserPreferencesProvider';
import { createEvent } from '../../../services/firestore/createEvent';
import { createNewSimulation } from '../../../services/firestore/createNewSimulation.js';
import { toggleSimulationFavorite } from '../../../services/firestore/toggleSimulationFavorite';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState.js';
import { Layout } from '../../Layout/Layout.js';
import { ProjectLoading } from '../../features/ProjectLoading.js';
import { ProjectInfoSmall } from '../Perspectives/features/ProjectSummary/features/ProjectInfoSmall.js';

import { SimulationOverview } from './features/SimulationOverview.js';

export const Simulations = () => {
  const { kaeplaUser } = useAuth();
  const { setPreferences, getPreferences } = useUserPreferences();
  const navigate = useNavigate();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [showAllSimulationsForProject, setShowAllSimulationsForProject] = useState(false);

  const handleShowAllSimulationsForProject = () => {
    if (!kaeplaUser?.uid) return;
    setShowAllSimulationsForProject(!showAllSimulationsForProject);
    setPreferences({
      showAllSimulations: !showAllSimulationsForProject,
    });
  };

  const create = async () => {
    if (!kaeplaUser || !currentScopePath) return;
    const _simulation = await createNewSimulation({
      project,
      scopePath: currentScopePath,
      createdBy: kaeplaUser.uid,
    });

    void toggleSimulationFavorite({
      uid: kaeplaUser.uid,
      simulation: _simulation,
      scopePath: currentScopePath,
      isFavorite: true,
    });
    navigate(`/Simulation/${project.id}/${_simulation.id}`);
  };

  useEffect(() => {
    const _preferences = getPreferences();
    if (_preferences) {
      setShowAllSimulationsForProject(!!_preferences.showAllSimulations);
    }
  }, [getPreferences]);

  useEffect(() => {
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.SIMULATIONS_ENTER_SIMULATIONS_OVERVIEW,
      functionGroup: KaeplaFunctionGroup.SIMULATIONS,
      operation: KaeplaDataOperation.READ,
      scopePath: currentScopePath,
      project,
    });
  }, [currentScopePath, kaeplaUser?.uid, project]);

  if (!project?.id) {
    return <ProjectLoading />;
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 4, lg: 3 }}>
              <ProjectInfoSmall title={project.name} />
            </Grid>
            <Grid size={2}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid
              size={{ xs: 12, sm: 6, md: 7 }}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <Typography variant="h6">Simulations</Typography>
              <Typography variant="body1">
                Create simulations and tweak numeric values and category-like dimensions for a
                specific set of records and see the effect on your data in your perspectives.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Toolbar disableGutters variant="dense">
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <FormControlLabel
            control={
              <Switch
                checked={showAllSimulationsForProject}
                size="small"
                onChange={handleShowAllSimulationsForProject}
              />
            }
            label="all"
          />
        </Toolbar>
        <Fab
          color="primary"
          aria-label="add simulation"
          data-testid="simulation-add"
          sx={{ position: 'fixed', bottom: 20, right: 20 }}
          onClick={() => {
            void create();
          }}
          disabled={!!project.currentImportId}
        >
          <AddIcon />
        </Fab>
        <SimulationOverview showAllSimulationsForProject={showAllSimulationsForProject} />
      </>
    </Layout>
  );
};

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  KaeplaSimulation,
  KaeplaSimulationRuleset,
  KaeplaSimulationRulesetWithParameters,
} from '@kaepla/types';
import AddIcon from '@mui/icons-material/AddOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DragHandleIcon from '@mui/icons-material/DragIndicatorOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterVisibleIcon from '@mui/icons-material/FilterAlt';
import FilterHiddenIcon from '@mui/icons-material/FilterAltOutlined';
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  Collapse,
  Divider,
  Grid2 as Grid,
  IconButton,
  IconButtonProps,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { updateSimulationWithScope } from '../../../../services/firestore/updateSimulationWithScope.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { cleanColumnName } from '../../../helpers/cleanColumnName.js';

import { AddSimulationParameterToRuleset } from './AddSimulationParameterToRuleset.js';
import { SimulationParameter } from './SimulationParameter.js';
import { SimulationRulesetFilter } from './SimulationRulesetFilter.js';

interface ExpandMoreProperties extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((properties: ExpandMoreProperties) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = properties;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Options {
  ruleset: KaeplaSimulationRuleset;
  simulation: KaeplaSimulation;
  setSimulation: Dispatch<SetStateAction<KaeplaSimulation | undefined>>;
  scopePath: string[];
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  setSimulationRulesets: Dispatch<
    SetStateAction<KaeplaSimulationRulesetWithParameters[] | undefined>
  >;
  setPreview: Dispatch<SetStateAction<boolean>>;
  setRuleset: Dispatch<SetStateAction<KaeplaSimulationRulesetWithParameters | undefined>>;
  own: boolean;
  sortableDisabled: boolean;
}

export const SimulationRuleset = ({
  ruleset,
  simulation,
  setSimulation,
  scopePath,
  simulationRulesets,
  setSimulationRulesets,
  setPreview,
  setRuleset,
  own,
  sortableDisabled,
}: Options) => {
  const theme = useTheme();
  const project = useRecoilValue(projectState);
  const [addingParameter, setAddingParameter] = useState<boolean>(false);
  const [toggleFilter, setToggleFilter] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(true);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: ruleset?.id || '-',
    disabled: sortableDisabled,
  });
  const equalizerParentReference = useRef<HTMLInputElement>(null);
  const simulationRuleset = simulationRulesets.find((s) => s.id === ruleset.id);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleExpandClick = () => {
    if (expanded) {
      setToggleFilter(false);
    }
    setExpanded(!expanded);
  };

  if (!project || !ruleset) return null;

  return (
    <Box ref={setNodeRef} style={style}>
      <Card sx={{ mb: 1 }}>
        <CardContent sx={{ p: 1 }}>
          <Grid container spacing={1}>
            <Grid size={10}>
              <Toolbar disableGutters variant="dense" sx={{ minHeight: 24 }}>
                <Avatar variant="rounded" sx={{ m: 1, width: 24, height: 24 }}>
                  {ruleset.position}
                </Avatar>
                {!expanded &&
                  simulationRuleset &&
                  simulationRuleset.parameters.map((parameter) => (
                    <Chip
                      key={parameter.dimension}
                      size="small"
                      sx={{ mr: 1 }}
                      label={`${cleanColumnName(parameter.dimension)}`}
                      variant="outlined"
                    />
                  ))}
                {!expanded && ruleset?.filter?.rulesCount && (
                  <Chip
                    size="small"
                    avatar={
                      <Avatar
                        sx={{
                          bgcolor: theme.palette.secondary.main,
                        }}
                      >
                        <Box
                          sx={{
                            color: theme.palette.getContrastText(theme.palette.secondary.main),
                          }}
                        >
                          {ruleset.filter.rulesCount}
                        </Box>
                      </Avatar>
                    }
                    sx={{ mr: 1 }}
                    label={`filter rules`}
                    variant="outlined"
                  />
                )}
                <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
                <Box sx={{ ml: 1, visibility: 'hidden', fontFamily: 'monospace', fontSize: 12 }}>
                  {ruleset.id}
                </Box>
              </Toolbar>
            </Grid>
            <Grid size={2} container justifyContent="flex-end">
              <Toolbar disableGutters variant="dense" sx={{ minHeight: 24 }}>
                <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
                <IconButton
                  aria-label="delete"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setToggleFilter(!toggleFilter);
                  }}
                  disabled={simulation.isBeingSimulated || addingParameter || !expanded || !own}
                >
                  <Badge invisible={!ruleset?.filter?.rulesCount} variant="dot" color="secondary">
                    {toggleFilter ? (
                      <FilterVisibleIcon fontSize="small" />
                    ) : (
                      <FilterHiddenIcon fontSize="small" />
                    )}
                  </Badge>
                </IconButton>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    setRuleset(simulationRuleset);
                    setAddingParameter(true);
                  }}
                  disabled={simulation.isBeingSimulated || addingParameter || !expanded || !own}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    const rulesets = [...simulation.rulesets].filter((r) => r.id !== ruleset.id);
                    const newSimulation = { ...simulation };
                    newSimulation.rulesets = rulesets;
                    void updateSimulationWithScope({
                      project,
                      simulation: newSimulation,
                      scopePath,
                    });
                    setSimulation(newSimulation);
                  }}
                  disabled={simulation.isBeingSimulated || !own}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
                <Box
                  data-testid="ruleset-draghandle"
                  sx={{ width: 20, height: 20, ml: 3, cursor: own ? 'grab' : 'inherit' }}
                  {...attributes}
                  {...listeners}
                >
                  <DragHandleIcon color="disabled" />
                </Box>
              </Toolbar>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ p: 0 }}>
            <Grid container>
              {addingParameter && simulationRuleset && own && (
                <Grid size={12}>
                  <AddSimulationParameterToRuleset
                    ruleset={simulationRuleset}
                    simulation={simulation}
                    setSimulation={setSimulation}
                    simulationRulesets={simulationRulesets}
                    setSimulationRulesets={setSimulationRulesets}
                    setAddingParameter={setAddingParameter}
                  />
                  <Divider />
                </Grid>
              )}
              {toggleFilter && own && (
                <Grid size={12}>
                  <SimulationRulesetFilter
                    ruleset={ruleset}
                    simulation={simulation}
                    setPreview={setPreview}
                  />
                  <Divider />
                </Grid>
              )}
              <Grid size={12} ref={equalizerParentReference}>
                {simulationRuleset &&
                  simulationRuleset.parameters?.map((parameter) => (
                    <SimulationParameter
                      key={parameter.dimension}
                      equalizerParentReference={equalizerParentReference}
                      parameter={parameter}
                      ruleset={simulationRuleset}
                      simulation={simulation}
                      own={own}
                      simulationRulesets={simulationRulesets}
                      setSimulationRulesets={setSimulationRulesets}
                      setPreview={setPreview}
                    />
                  ))}
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </Box>
  );
};

import { KaeplaDataOperation, KaeplaEventType, KaeplaFunctionGroup } from '@kaepla/types';
import { Box, Grid2 as Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { createEvent } from '../../../services/firestore/createEvent.js';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState.js';
import { knownResellersState } from '../../../services/recoil/nonpersistent/knownResellersState.js';
import { Layout } from '../../Layout/Layout.js';

import { CreateResellerFab } from './features/CreateResellerFab.js';
import { ResellerCard } from './features/ResellerCard.js';

export const Resellers = () => {
  const { kaeplaUser } = useAuth();
  const app = useRecoilValue(applicationState);
  const knownResellers = useRecoilValue(knownResellersState);

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!app.initialized) return;
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.RESELLERS_ENTER_RESELLERS_OVERVIEW,
      functionGroup: KaeplaFunctionGroup.RESELLER,
      operation: KaeplaDataOperation.READ,
    });
  }, [app.initialized, kaeplaUser?.uid]);

  return (
    <Layout>
      <Box>
        <Grid container spacing={1}>
          <Grid size={12}>
            <Typography variant="h4">Resellers</Typography>
          </Grid>
          {knownResellers
            .filter((k) => !!k)
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((reseller) => (
              <ResellerCard key={reseller.id} reseller={reseller} />
            ))}
        </Grid>
        <CreateResellerFab />
      </Box>
    </Layout>
  );
};

import FullScreenIcon from '@mui/icons-material/FullscreenOutlined';
import { IconButton, Stack, Typography } from '@mui/material';

import { KaeplaDataView } from '../../../../../../typings/KaeplaDataView';

import { GridSearch } from './GridSearch';

interface GOptions {
  searchTerm: string | undefined;
  setSearchTerm: (value: string) => void;
  onSearchTermChange: () => void;
  setDataView: (value: KaeplaDataView) => void;
}
export const GridMenuTableView = ({
  searchTerm,
  setSearchTerm,
  onSearchTermChange,
  setDataView,
}: GOptions) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
      padding={1}
    >
      <GridSearch
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSearchTermChange={onSearchTermChange}
      />
      <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
      <IconButton
        size="small"
        data-testid="user-dialog-close"
        onClick={() => {
          setDataView(KaeplaDataView.Grid);
        }}
      >
        <FullScreenIcon />
      </IconButton>
    </Stack>
  );
};

// eslint-disable-next-line import/no-unassigned-import
import './logging';

import { LinkProps } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { forwardRef, useMemo, useState } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Link as RouterLink,
  LinkProps as RouterLinkProperties,
  Routes,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AuthReactProvider, useAuth } from './AuthReactProvider.js';
import { ColorModeContext } from './Frontend/Layout/features/ColorModeToggle.js';
import { Branding } from './Frontend/ListenerComponents/Branding.js';
import { CustomerAssignmentsListener } from './Frontend/ListenerComponents/CustomerAssignmentsListener.js';
import { FiltersListener } from './Frontend/ListenerComponents/FiltersListener.js';
import { KaeplaAssignmentsListener } from './Frontend/ListenerComponents/KaeplaAssignmentsListener.js';
import { LastProjectAndCustomer } from './Frontend/ListenerComponents/LastProjectAndCustomer.js';
import { ProjectAssignmentsListener } from './Frontend/ListenerComponents/ProjectAssignmentsListener.js';
import { ProjectListener } from './Frontend/ListenerComponents/ProjectListener.js';
import { ResellerAssignmentsListener } from './Frontend/ListenerComponents/ResellerAssignmentsListener.js';
import { SimulationFavoritesListener } from './Frontend/ListenerComponents/SimulationFavoritesListener.js';
import { SimulationsListener } from './Frontend/ListenerComponents/SimulationsListener.js';
import { TargetsListener } from './Frontend/ListenerComponents/TargetsListener.js';
import { UserListenerKaeplaAdmin } from './Frontend/ListenerComponents/UserListenerKaeplaAdmin.js';
import { UserListenerUsers } from './Frontend/ListenerComponents/UserListenerUsers.js';
import { Assignments } from './Frontend/Screens/Assignments/Assignments.js';
import { Connectors } from './Frontend/Screens/Connectors/Connectors.js';
import { Customers } from './Frontend/Screens/Customers/Customers.js';
import { DataSnapshots } from './Frontend/Screens/DataSnapshots/DataSnapshots.js';
import { DataSources } from './Frontend/Screens/DataSources/DataSources.js';
import { Disclaimers } from './Frontend/Screens/Disclaimers/Disclaimers.js';
import { Events } from './Frontend/Screens/Events/Events.js';
import { ExperimentalAi } from './Frontend/Screens/ExperimentalAi/ExperimentalAi.js';
import { Login } from './Frontend/Screens/Login/Login.js';
import { Logout } from './Frontend/Screens/Login/Logout.js';
import { MoreLoginOptions } from './Frontend/Screens/Login/features/MoreLoginOptions.js';
import { Operations } from './Frontend/Screens/Operations/Operations.js';
import { Perspective } from './Frontend/Screens/Perspectives/Perspective.js';
import { Perspectives } from './Frontend/Screens/Perspectives/Perspectives.js';
import { Projects } from './Frontend/Screens/Projects/Projects.js';
import { Resellers } from './Frontend/Screens/Resellers/Resellers.js';
import { Scopes } from './Frontend/Screens/Scopes/Scopes.js';
import { SimulationEditor } from './Frontend/Screens/Simulations/SimulationEditor/SimulationEditor.js';
import { Simulations } from './Frontend/Screens/Simulations/Simulations.js';
import { ImportLog } from './Frontend/Screens/SyncData/ImportLog/ImportLog.js';
import { SyncData } from './Frontend/Screens/SyncData/SyncData.js';
import { Targets } from './Frontend/Screens/Targets/Targets.js';
import { TargetsEditor } from './Frontend/Screens/Targets/TargetsEditor.js';
import { User } from './Frontend/Screens/User/User.js';
import { Users } from './Frontend/Screens/Users/Users.js';
import { ValidateData } from './Frontend/Screens/ValidateData/ValidateData.js';
import { themeOptions } from './Frontend/Theme/KaeplaTheme.js';
import { logger } from './Frontend/helpers/logger.js';
import { KaeplaErrorProvider } from './KaeplaErrorProvider.js';
import { UserPreferencesProvider } from './UserPreferencesProvider.js';
import { AdminRoute } from './routes/admin/AdminRoute';

const queryClient = new QueryClient();

const Routing = () => {
  const { authState } = useAuth();

  if (authState === null) return null;

  logger.log('App renders');

  if (!authState) {
    return (
      <Routes>
        <Route path="/*" element={<Login />} />
        <Route path="/LoginOptions" element={<MoreLoginOptions />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route index path="/" element={<Projects />} />
      <Route path="/Projects" element={<Projects />} />
      <Route path="/Project/:projectId" element={<Perspective />} />

      <Route path="/Assignments" element={<Assignments />} />
      <Route path="/Assignments/:projectId" element={<Assignments />} />

      {/* this is deactivated in the menu */}
      <Route path="/ExperimentalAi" element={<ExperimentalAi />} />
      <Route path="/ExperimentalAi/:projectId" element={<ExperimentalAi />} />

      <Route path="/Perspectives" element={<Perspectives />} />
      <Route path="/Perspectives/:projectId" element={<Perspectives />} />
      <Route path="/Perspective/:projectId" element={<Perspective />} />
      <Route path="/Perspective/:projectId/:id" element={<Perspective />} />

      <Route path="/Simulations" element={<Simulations />} />
      <Route path="/Simulations/:projectId" element={<Simulations />} />
      <Route path="/Simulation/:projectId/:id" element={<SimulationEditor />} />

      <Route path="/Targets" element={<Targets />} />
      <Route path="/Targets/:projectId" element={<Targets />} />
      <Route path="/Targets/:projectId/:id" element={<TargetsEditor />} />

      <Route path="/Scopes" element={<Scopes />} />
      <Route path="/Scopes/:projectId" element={<Scopes />} />

      <Route path="/Events" element={<Events />} />
      <Route path="/Events/:projectId" element={<Events />} />

      <Route path="/Operations" element={<Operations />} />
      <Route path="/Operations/:projectId" element={<Operations />} />

      <Route path="/SyncData" element={<SyncData />} />
      <Route path="/SyncData/:projectId" element={<SyncData />} />
      <Route path="/SyncData/:projectId/:logId" element={<ImportLog />} />

      <Route path="/ValidateData" element={<ValidateData />} />
      <Route path="/ValidateData/:projectId" element={<ValidateData />} />

      <Route path="/DataSnapshots" element={<DataSnapshots />} />
      <Route path="/DataSnapshots/:projectId" element={<DataSnapshots />} />

      <Route path="/DataSources" element={<DataSources />} />
      <Route path="/DataSources/:projectId" element={<DataSources />} />

      <Route path="/Disclaimers" element={<Disclaimers />} />

      <Route path="/admin/*" element={<AdminRoute />} />

      <Route path="/Users" element={<Users />} />

      <Route path="/User" element={<User />} />
      <Route path="/User/:action/:token" element={<User />} />

      <Route path="/Customers" element={<Customers />} />
      <Route path="/Customers/:resellerId" element={<Customers />} />

      <Route path="/Connectors" element={<Connectors />} />
      <Route path="/Connectors/:customerId" element={<Connectors />} />

      <Route path="/Resellers" element={<Resellers />} />

      <Route path="/Logout" element={<Logout />} />
      <Route path="/Login" element={<Navigate to="/Projects" />} />
      <Route path="/LoginOptions" element={<Navigate to="/Projects" />} />
    </Routes>
  );
};

/*
useEffect fires twice in strict mode, putting double load on BQ
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AppWithStrictMode = () => {
  return (
    <StrictMode>
      <RecoilRoot>
        <AuthProvider>
          <KaeplaAssignmentsListener />
          <ResellerAssignmentsListener />
          <CustomerAssignmentsListener />
          <UpdateKnownUsers />
        <Router>
          <Routing />
        </Router>
        </AuthProvider>
      </RecoilRoot>
    </StrictMode>
  );
};
*/

const AppWithoutStrictMode = () => {
  return (
    <RecoilRoot>
      <AuthReactProvider>
        <UserPreferencesProvider>
          <KaeplaErrorProvider>
            <Branding />
            <LastProjectAndCustomer />
            <KaeplaAssignmentsListener />
            <ResellerAssignmentsListener />
            <CustomerAssignmentsListener />
            <ProjectAssignmentsListener />
            <SimulationsListener showAllSimulationsForProject={true} />
            <SimulationFavoritesListener showAllSimulationsForProject={true} />
            <TargetsListener showAllTargetsForProject={true} />
            <FiltersListener />
            <UserListenerKaeplaAdmin />
            <UserListenerUsers />
            <ProjectListener />
            <Router>
              <Routing />
            </Router>
          </KaeplaErrorProvider>
        </UserPreferencesProvider>
      </AuthReactProvider>
    </RecoilRoot>
  );
};

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProperties, 'to'> & { href: RouterLinkProperties['to'] }
>((properties, reference) => {
  const { href, ...other } = properties;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={reference} to={href} {...other} />;
});

export const App = () => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((previousMode) => (previousMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme(
        {
          components: {
            MuiTab: {
              defaultProps: {
                component: LinkBehavior,
              },
            },
            MuiLink: {
              defaultProps: {
                component: LinkBehavior,
              } as LinkProps,
            },
            MuiButtonBase: {
              defaultProps: {
                LinkComponent: LinkBehavior,
              },
            },
          },
          palette: {
            mode,
          },
        },
        themeOptions,
      ),
    [mode],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <AppWithoutStrictMode />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </QueryClientProvider>
  );
};

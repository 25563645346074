import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { MatrixTimeSlice } from '@kaepla/types';

import { useAuth } from '../../../../AuthReactProvider.js';
import { createProject } from '../../../../services/firestore/createProject.js';
import { applicationState } from '../../../../services/recoil/nonpersistent/applicationState.js';
import { customerAssignmentState } from '../../../../services/recoil/nonpersistent/customerAssignmentState.js';
import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState.js';
import { projectInCreationState } from '../../../../services/recoil/nonpersistent/projectInCreationState.js';
import { scopePathsState } from '../../../../services/recoil/nonpersistent/scopePathsState.js';
import { selectedCustomerState } from '../../../../services/recoil/nonpersistent/selectedCustomer.js';
import { simulationState } from '../../../../services/recoil/nonpersistent/simulationState.js';
import { snapShotState } from '../../../../services/recoil/nonpersistent/snapshotState.js';
import { lastProjectIdState } from '../../../../services/recoil/persistent/lastProjectState.js';
import { timeSliceState } from '../../../../services/recoil/persistent/timeSliceState.js';

export const CreateProjectFab = () => {
  const { kaeplaUser } = useAuth();
  const app = useRecoilValue(applicationState);
  const setScopePaths = useSetRecoilState(scopePathsState);
  const setTimeSlice = useSetRecoilState(timeSliceState);
  const setLastProject = useSetRecoilState(lastProjectIdState);
  const setProjectInCreation = useSetRecoilState(projectInCreationState);
  const resetMatrixFiltered = useResetRecoilState(matrixFilteredState);
  const resetSimulation = useResetRecoilState(simulationState);
  const resetSnapshot = useResetRecoilState(snapShotState);
  const customerAssignments = useRecoilValue(customerAssignmentState);
  const selectedCustomer = useRecoilValue(selectedCustomerState);

  if (!kaeplaUser || !selectedCustomer || !app.initialized) return null;
  const canCreateProject = customerAssignments.find(
    (c) => c.customerId === selectedCustomer.id,
  )?.canCreateProject;

  if (!canCreateProject) {
    return null;
  }

  const create = async () => {
    setProjectInCreation('n/a');
    const newProject = await createProject({
      kaeplaUser,
      customer: selectedCustomer,
    });
    setProjectInCreation(newProject.id);

    const newScopePaths: Record<string, string[] | undefined> = {};
    newScopePaths[newProject.id] = [];
    setScopePaths(newScopePaths);
    resetMatrixFiltered();
    resetSimulation();
    resetSnapshot();
    setTimeSlice('YEAR' as MatrixTimeSlice);
    setLastProject(newProject.id);
  };

  return (
    <Fab
      color="primary"
      id="project-add-fab"
      aria-label="add project"
      data-testid="project-add"
      sx={{ position: 'fixed', bottom: 20, right: 20 }}
      onClick={() => {
        void create();
      }}
    >
      <AddIcon />
    </Fab>
  );
};

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Menu,
  ListItemButton,
  List,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { KaeplaCustomer } from '@kaepla/types';

import { knownCustomersState } from '../../services/recoil/nonpersistent/knownCustomersState.js';
import { knownProjectsState } from '../../services/recoil/nonpersistent/knownProjectsState.js';
import { knownResellersState } from '../../services/recoil/nonpersistent/knownResellersState.js';
import { projectsOverviewState } from '../../services/recoil/nonpersistent/projectsOverviewState.js';
import { selectedCustomerState } from '../../services/recoil/nonpersistent/selectedCustomer.js';
import { lastCustomerIDState } from '../../services/recoil/persistent/lastCustomerState';
import { lastProjectIdState } from '../../services/recoil/persistent/lastProjectState.js';

export const CustomerSelector = () => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const knownResellers = useRecoilValue(knownResellersState);
  const knownCustomers = useRecoilValue(knownCustomersState);
  const knownProjects = useRecoilValue(knownProjectsState);
  const resetLastProject = useResetRecoilState(lastProjectIdState);
  const setProjectsOverview = useSetRecoilState(projectsOverviewState);
  const setLastCustomer = useSetRecoilState(lastCustomerIDState);
  const [selectedCustomer, setSelectedCustomer] = useRecoilState(selectedCustomerState);

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  // only show customer selector if more than 1 customer assignment
  if (knownCustomers.length <= 1) return null;

  const resellerFromCustomer = (c: KaeplaCustomer | undefined) => {
    return knownResellers.filter((k) => !!k).find((r) => r.id === c?.resellerId);
  };

  const reseller = resellerFromCustomer(selectedCustomer);

  return (
    <>
      <List id="customer-selector" data-testid="customer-selector" dense disablePadding>
        <ListItemButton onClick={handleClick} dense disableGutters>
          {selectedCustomer && (
            <ListItemIcon sx={{ minWidth: 24 }}>
              <img width="16" alt={selectedCustomer.name} src={selectedCustomer.iconBase64} />
            </ListItemIcon>
          )}
          {smUp && (
            <ListItemText
              sx={{ mt: 0.8 }}
              primary={selectedCustomer ? selectedCustomer.name : ''}
            />
          )}
          {smUp && reseller && (
            <ListItemIcon sx={{ pl: 2 }}>
              <img width="12" alt={reseller.name} src={reseller.iconBase64} />
            </ListItemIcon>
          )}
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </List>
      <Menu id="simulationSelect" anchorEl={anchorElement} open={open} onClose={handleClose}>
        <MenuItem
          dense
          value={undefined}
          selected={!selectedCustomer}
          onClick={(_event) => {
            resetLastProject();
            setSelectedCustomer(undefined);
            setProjectsOverview(knownProjects);
            handleClose();
            navigate('/');
          }}
        >
          <ListItemIcon>
            <Box border={1} width={16} height={16} borderRadius={8} />
          </ListItemIcon>
          <ListItemText primary="All" />
          <ListItemIcon sx={{ pl: 2 }}>
            <Box border={1} width={12} height={12} />
          </ListItemIcon>
        </MenuItem>
        {knownCustomers
          .filter((c) => c !== undefined)
          .map((knownCustomer) => {
            const customerReseller = resellerFromCustomer(knownCustomer);
            return (
              <MenuItem
                dense
                key={knownCustomer.id}
                value={knownCustomer.id}
                selected={selectedCustomer && selectedCustomer.id === knownCustomer.id}
                onClick={(_event) => {
                  resetLastProject();
                  setSelectedCustomer(knownCustomer);
                  setLastCustomer(knownCustomer.id);
                  setProjectsOverview(
                    knownProjects.filter((p) => p.customerId === knownCustomer?.id),
                  );
                  handleClose();
                  navigate('/');
                }}
              >
                <ListItemIcon>
                  <img width="16" alt={knownCustomer.name} src={knownCustomer.iconBase64} />
                </ListItemIcon>
                <ListItemText primary={knownCustomer.name} />
                {customerReseller && (
                  <ListItemIcon sx={{ pl: 2 }}>
                    <img width="12" alt={customerReseller.name} src={customerReseller.iconBase64} />
                  </ListItemIcon>
                )}
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

import { Alert, AlertTitle, Box, Skeleton, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
// eslint-disable-next-line import/order
import {
  Chart as ChartJS,
  TimeSeriesScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  Point,
  Filler,
  ChartOptions,
  TimeUnit,
} from 'chart.js';

// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unassigned-import
import 'chartjs-adapter-luxon';

import numbro from 'numbro';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Md5 } from 'ts-md5';

import { chartDataState } from '../../../../../services/recoil/nonpersistent/chartDataState';
import { perspectiveState } from '../../../../../services/recoil/nonpersistent/perspectiveState.js';
import { perspectiveTargetFiguresState } from '../../../../../services/recoil/nonpersistent/perspectiveTargetFigures';
import { simulationState } from '../../../../../services/recoil/nonpersistent/simulationState';
import { snapShotState } from '../../../../../services/recoil/nonpersistent/snapshotState';
import { timeSeriesState } from '../../../../../services/recoil/nonpersistent/timeSeriesState.js';
import { currentScopePathState } from '../../../../../services/recoil/persistent/currentScopePathState';
import { filterSettingsState } from '../../../../../services/recoil/persistent/filterSettingState.js';
import { filterSqlState } from '../../../../../services/recoil/persistent/filterSqlState';
import { timeSliceState } from '../../../../../services/recoil/persistent/timeSliceState';
import {
  simulationPreviewDataColor,
  simulationPreviewDataColorDM,
  simulationPreviewDataColorLight,
  simulationPreviewDataColorLightDM,
} from '../../../defaults';

import { getChartDataForTimeseries } from './getChartDataForTimeseries';

ChartJS.register(
  TimeSeriesScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const options: ChartOptions<'line'> = {
  animation: {
    duration: 1000,
  },
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 2,
    },
    line: {
      borderWidth: 1,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'year',
      },
    },
    y: {
      ticks: {
        callback: (value: string | number) => {
          return (
            '€' +
            numbro(value).format({
              average: true,
              mantissa: 1,
            })
          );
        },
      },
    },
  },
};

interface Options {
  simulationPreview?: boolean;
  loading: boolean;
}

export const DataTimelineChart = ({ simulationPreview, loading }: Options) => {
  const theme = useTheme();
  const previewColor =
    theme.palette.mode === 'light' ? simulationPreviewDataColor : simulationPreviewDataColorDM;
  const previewColorLight =
    theme.palette.mode === 'light'
      ? simulationPreviewDataColorLight
      : simulationPreviewDataColorLightDM;
  const perspective = useRecoilValue(perspectiveState);
  const simulation = useRecoilValue(simulationState);
  const snapshot = useRecoilValue(snapShotState);
  const filterSql = useRecoilValue(filterSqlState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const scopePathStringified = JSON.stringify(currentScopePath);
  const chartKey = Md5.hashStr(
    `tl-${perspective?.id ?? 'p'}${simulation?.id ?? snapshot?.id ?? 's'}${filterSql ?? 'f'}${scopePathStringified}${filterSettings.isActive ? 't' : 'f'}`,
  );
  const perspectiveTargetFigures = useRecoilValue(perspectiveTargetFiguresState);
  const timeSeries = useRecoilValue(timeSeriesState);
  const timeSlice = useRecoilValue(timeSliceState);
  const [allChartData, setAllChartData] = useRecoilState(chartDataState);
  const [data, setData] = useState<ChartData<'line', (number | Point | null)[], unknown>>(
    allChartData?.[chartKey] as ChartData<'line', (number | Point | null)[], unknown>,
  );
  const [chartOptions, setChartOptions] = useState<ChartOptions<'line'>>(options);
  const [dataLength, setDataLength] = useState<number>();
  const [initialized, setInitialized] = useState(false);

  // pre-use the locally stored chart data
  useEffect(() => {
    if (!initialized) return;
    if (perspective?.id && allChartData?.[chartKey]) {
      const _chartData = allChartData[chartKey] as ChartData<
        'line',
        (number | Point | null)[],
        unknown
      >;

      setData(_chartData);

      if (_chartData?.datasets?.[0]?.data.length > 0) {
        setDataLength(_chartData.datasets[0].data.length);
      } else {
        setDataLength(0);
      }
    } else {
      setDataLength(0);
    }
  }, [allChartData, chartKey, data, initialized, loading, perspective?.id]);

  useEffect(() => {
    if (!perspective?.id) return;
    if (!timeSeries?.timeseries) return;

    const _data = getChartDataForTimeseries({
      timeSeries,
      perspective,
      simulationPreview: simulationPreview ?? false,
      previewColor,
      previewColorLight,
      perspectiveTargetFigures,
      timeSlice,
    });

    setChartOptions((_options) => {
      const optionsWithTimeSlice = { ..._options };
      let timeUnit = timeSlice.toLowerCase() as TimeUnit;
      if (timeUnit === 'quarter') {
        timeUnit = 'year';
      }
      if (optionsWithTimeSlice.scales?.x?.type === 'time' && optionsWithTimeSlice.scales.x.time) {
        optionsWithTimeSlice.scales.x.time.unit = timeUnit;
      }
      return optionsWithTimeSlice;
    });

    setAllChartData((previous) => {
      return {
        ...previous,
        [chartKey]: _data as unknown as Record<string, unknown>,
      };
    });

    setInitialized(true);
  }, [
    timeSeries?.timeseries,
    simulationPreview,
    perspective?.aggregationOverTimeName,
    previewColor,
    previewColorLight,
    perspectiveTargetFigures,
    timeSlice,
    perspective?.id,
    setAllChartData,
    chartKey,
    perspective,
    timeSeries,
  ]);

  if (!dataLength) {
    return (
      <Box height={200}>
        <Skeleton sx={{ height: '100%', bgcolor: grey[100] }} variant="rectangular" />
      </Box>
    );
  } else if (dataLength === 0) {
    return (
      <Box sx={{ height: '100%', width: '100%', bgcolor: grey[100], p: 4 }}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Alert severity="warning">
            <AlertTitle>NO DATA</AlertTitle>
            We could not find any data for this scope
            {filterSettings.isActive && <Box sx={{ fontWeight: 800 }}>Check your filters!</Box>}
          </Alert>
        </Stack>
      </Box>
    );
  }

  return (
    <Box height={200}>
      <Line options={chartOptions} data={data} />
    </Box>
  );
};

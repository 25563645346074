import { Divider, List, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { KaeplaAuthenticationProvider } from '@kaepla/types';

import { useAuth } from '../../../../../AuthReactProvider';
import { brandingState } from '../../../../../services/recoil/persistent/brandingState';

import { AuthProvider } from './AuthProvider';
import { defaultAuthProviders } from './defaultAuthProviders';
import { oAuth2Providers } from './oAuth2Providers';


interface Options {
  setAuthMessage: (message: string | null) => void;
}

export const AuthProviders = ({ setAuthMessage }: Options) => {
  const { user } = useAuth();
  const [availableProviders, setAvailableProviders] = useState<KaeplaAuthenticationProvider[]>([]);
  const [userProviders, setUserProviders] = useState<KaeplaAuthenticationProvider[]>([]);
  const [allProviders, setAllProviders] = useState<KaeplaAuthenticationProvider[]>([]);
  const alwaysAvailableProviders = allProviders.filter((p) => p.alwaysAvailable);
  const branding = useRecoilValue(brandingState);

  useEffect(() => {
    let _allProviders = [...defaultAuthProviders, ...oAuth2Providers];
    if (branding?.authenticationConfiguration?.configuration.samlProvider) {
      _allProviders = [];
    }
    setAllProviders(_allProviders);

    const _userProviders = _allProviders.filter((p) =>
      user?.providerData.map((userInfo) => userInfo.providerId).includes(p.providerId),
    );
    if (branding?.authenticationConfiguration?.configuration.samlProvider) {
      const samlProvider: KaeplaAuthenticationProvider = {
        providerId: branding?.authenticationConfiguration?.configuration.samlProvider,
        providerPlatform: branding?.authenticationConfiguration?.configuration.providerPlatform,
        alwaysAvailable: true,
        type: 'saml',
        label: 'Single Sign-On',
        active: true,
      };
      if (_userProviders.map((p) => p.providerId).includes(samlProvider.providerId) === false) {
        _userProviders.push(samlProvider);
      }
    }
    setUserProviders(_userProviders);
    const _availableProviders = [..._allProviders].filter(
      (p) =>
        !_userProviders.map((userProvider) => userProvider.providerId).includes(p.providerId) &&
        !p.alwaysAvailable,
    );
    setAvailableProviders(_availableProviders);
  }, [branding, user?.providerData]);

  return (
    <Paper sx={{ p: 2 }} data-testid="user-AuthProviderOptions">
      <List dense>
        {alwaysAvailableProviders.map((provider) => (
          <AuthProvider
            key={provider.providerId}
            setAuthMessage={setAuthMessage}
            provider={provider}
            isActiveForUser={false}
          />
        ))}
        {userProviders.map((provider) => {
          return (
            <AuthProvider
              key={provider.providerId}
              setAuthMessage={setAuthMessage}
              isActiveForUser={true}
              provider={provider}
            />
          );
        })}
        {availableProviders.length > 0 && <Divider sx={{ my: 2 }} />}
        {availableProviders.map((provider) => (
          <AuthProvider
            key={provider.providerId}
            setAuthMessage={setAuthMessage}
            provider={provider}
            isActiveForUser={false}
          />
        ))}
      </List>
    </Paper>
  );
};

/* eslint-disable import/no-unassigned-import */
import { KaeplaGridSetStored, KaeplaGridSetting, KaeplaPivotParameters } from '@kaepla/types';
import { Box, Paper } from '@mui/material';
import {
  GridPreDestroyedEvent,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  SideBarDef,
  StateUpdatedEvent,
} from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';

import { useAuth } from '../../../../../AuthReactProvider';
import { getDataForGrid } from '../../../../../services/api/getDataForGrid';
import { applicationState } from '../../../../../services/recoil/nonpersistent/applicationState';
import { dataGridSettings } from '../../../../../services/recoil/nonpersistent/dataGridSets';
import { dataGridState } from '../../../../../services/recoil/nonpersistent/dataGridState';
import { dataViewState } from '../../../../../services/recoil/nonpersistent/dataViewState';
import { gridColumnState } from '../../../../../services/recoil/nonpersistent/gridColumnState';
import { matrixFilteredState } from '../../../../../services/recoil/nonpersistent/matrixFilteredState';
import { perspectiveState } from '../../../../../services/recoil/nonpersistent/perspectiveState';
import { projectState } from '../../../../../services/recoil/nonpersistent/projectState';
import { simulationState } from '../../../../../services/recoil/nonpersistent/simulationState';
import { snapShotState } from '../../../../../services/recoil/nonpersistent/snapshotState';
import { currentScopePathState } from '../../../../../services/recoil/persistent/currentScopePathState';
import { filterSettingsState } from '../../../../../services/recoil/persistent/filterSettingState';
import { filterSqlState } from '../../../../../services/recoil/persistent/filterSqlState';
import { KaeplaDataView } from '../../../../../typings/KaeplaDataView';
import { Filters } from '../../../../features/Filters/Filters';
import { snapshotColor } from '../../../../Theme/colors';
import { simulationDataColor } from '../../../defaults';

import { ExportDialog } from './ExportDialog';
import {
  initialAutoGroupColumnDefinition,
  initialColumnDefinition,
  initialSideBar,
} from './gridConfigs';
import { GridMenu } from './GridMenu/GridMenu';
import { GridSets } from './GridSets/GridSets';
import { getColumnDefinitions } from './helpers/getColumnDefinitions';
import { getContextMenuItems } from './helpers/getContextMenuItems';

import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-quartz.css';
import './style.css';

LicenseManager.setLicenseKey(
  `Using_this_{AG_Grid}_Enterprise_key_{AG-064886}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Atrigam_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Kaepla}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Kaepla}_need_to_be_licensed___{Kaepla}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_August_2025}____[v3]_[01]_MTc1NDk1MzIwMDAwMA==4fb0cd1dbd971b5836c40b4bead31a90`,
);

export const PivotView = () => {
  const { kaeplaUser } = useAuth();
  const application = useRecoilValue(applicationState);
  const columnState = useRecoilValue(gridColumnState);
  const [gridState, setGridState] = useRecoilState(dataGridState);
  const [gridSettings, setGridSettings] = useRecoilState(dataGridSettings);
  const filterSettings = useRecoilValue(filterSettingsState);
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const simulation = useRecoilValue(simulationState);
  const snapshot = useRecoilValue(snapShotState);
  const project = useRecoilValue(projectState);
  const perspective = useRecoilValue(perspectiveState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const filterSql = useRecoilValue(filterSqlState);
  const [dataView, setDataView] = useRecoilState(dataViewState);
  const containerStyle = useMemo(() => ({ width: '100%', height: '90%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridReference = useRef<AgGridReact>(null);
  const [searchTerm, setSearchTerm] = useState<string>();
  const compareColor = simulation?.id ? simulationDataColor : snapshotColor;
  const [gridParameters, setGridParameters] = useState<KaeplaPivotParameters>();
  const [downloading, setDownloading] = useState<boolean>();
  const [downloadSuccess, setDownloadSuccess] = useState<boolean>();
  const [downloadError, setDownloadError] = useState<string>();
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [gridVisible, setGridVisible] = useState(true);
  const [showSavedGrids, setShowSavedGrids] = useState(false);
  const [currentGridId, setCurrentGridId] = useState<string>();
  const [selectedGridSet, setSelectedGridSet] = useState<KaeplaGridSetStored | null>(null);
  const [tabNumber, setTabNumber] = useState(0);

  const handleExportDialogOpen = () => {
    setExportDialogOpen(true);
  };

  const defaultColDefinition = useMemo(() => {
    return initialColumnDefinition;
  }, []);

  const autoGroupColumnDefinition = useMemo(() => {
    return initialAutoGroupColumnDefinition;
  }, []);

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return initialSideBar;
  }, []);

  const getRows = useCallback(
    (pivotParameters: IServerSideGetRowsParams) => {
      if (!kaeplaUser?.uid) return;
      if (!currentScopePath) return;
      if (!matrixFiltered?.dimensions?.dimensions) return;
      // console.log('column defs ->', gridReference.current!.api.getColumnDefs());
      const pivotRequestParameters = pivotParameters.request;

      const parameters: KaeplaPivotParameters = {
        uid: kaeplaUser.uid,
        scopePath: currentScopePath,
        projectId: project.id,
        simulationId: simulation?.id,
        snapshotId: snapshot?.id,
        pivotRequestParameters,
        searchTerm,
        info: 'PivotView',
      };

      if (filterSettings.isActive) {
        parameters.filterSql = filterSql;
      }
      setGridParameters(parameters);
      const callGetDataForGrid = async () => {
        const rowData = (await getDataForGrid({
          params: parameters,
          uid: kaeplaUser?.uid,
          setError: () => {
            return;
          },
        })) as unknown[];
        if (rowData) {
          pivotParameters.success({ rowData });
        } else {
          pivotParameters.fail();
        }
      };
      void callGetDataForGrid();
    },
    [
      currentScopePath,
      filterSettings.isActive,
      filterSql,
      kaeplaUser?.uid,
      matrixFiltered?.dimensions?.dimensions,
      project.id,
      searchTerm,
      simulation?.id,
      snapshot?.id,
    ],
  );

  const onSearchTermChange = useDebouncedCallback(() => {
    const dataSource: IServerSideDatasource = {
      getRows,
    };
    gridReference.current!.api.setGridOption('serverSideDatasource', dataSource);
  }, 1000);

  const onGridReady = useCallback(
    (_parameters_: GridReadyEvent<Record<string, unknown>>) => {
      if (!matrixFiltered?.dimensions?.dimensions) {
        return;
      }
      const dimensions = matrixFiltered?.dimensions?.dimensions;
      const columnDefs = getColumnDefinitions(dimensions, perspective, compareColor);
      gridReference.current!.api.setGridOption('columnDefs', columnDefs);
      // other settings
      gridReference.current!.api.autoSizeAllColumns();

      // register the datasource with the grid
      const dataSource: IServerSideDatasource = {
        getRows,
      };
      gridReference.current!.api.setGridOption('serverSideDatasource', dataSource);
    },
    [matrixFiltered?.dimensions?.dimensions, perspective, compareColor, getRows],
  );

  const persistGridState = useCallback(
    (parameters: StateUpdatedEvent) => {
      if (dataView !== KaeplaDataView.Grid) return;
      setGridState(parameters.state);
      if (currentGridId && showSavedGrids) {
        const newGridSets = [...gridSettings].map((gridSet) => {
          if (gridSet.id === currentGridId) {
            return { ...gridSet, gridState: parameters.state };
          }
          return gridSet;
        });
        setGridSettings(newGridSets);
      }
    },
    [currentGridId, dataView, gridSettings, setGridSettings, setGridState, showSavedGrids],
  );

  const loadColumnState = useCallback(() => {
    if (columnState.length > 0 && gridSettings.length === 0 && dataView === KaeplaDataView.Grid) {
      gridReference.current!.api.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  }, [columnState, dataView, gridSettings]);

  const addGridStateToGridSet = useCallback(() => {
    if (dataView !== KaeplaDataView.Grid) return;
    setGridSettings((oldGridSets) => {
      const gridSet: KaeplaGridSetting = {
        id: uuidv4(),
        name: `Grid ${oldGridSets.length + 1}`,
        gridState: gridReference.current!.api.getState(),
      };
      return [...oldGridSets, gridSet];
    });
  }, [dataView, setGridSettings]);

  const loadGridState = useCallback(
    (id: string, newGridSettings: KaeplaGridSetting[]) => {
      if (dataView !== KaeplaDataView.Grid) return;
      const newGridState = newGridSettings.find((gridSet) => gridSet.id === id)?.gridState;
      if (!newGridState) return;
      gridReference.current!.api.destroy(); // this is needed!
      setGridVisible(false);
      setGridState(newGridState);
      setTimeout(() => {
        setGridVisible(true);
        setCurrentGridId(id);
      });
    },
    [dataView, setGridState],
  );

  const onGridPreDestroyed = useCallback(
    (parameters: GridPreDestroyedEvent) => {
      if (dataView !== KaeplaDataView.Grid) return;
      setGridState(parameters.state);
    },
    [dataView, setGridState],
  );

  // this re-renders the grid when the simulation, filter or scope changes
  useEffect(() => {
    if (!gridReference.current!.api) {
      return;
    }
    if (!matrixFiltered?.dimensions?.dimensions) {
      return;
    }
    const dataSource: IServerSideDatasource = {
      getRows,
    };

    // register the datasource with the grid
    gridReference.current!.api.setGridOption('serverSideDatasource', dataSource);
  }, [getRows, matrixFiltered?.dimensions?.dimensions, simulation, filterSql]);

  // this does the setup of the grid column definitions
  useEffect(() => {
    if (!gridReference.current!.api) {
      return;
    }
    if (!matrixFiltered?.dimensions?.dimensions) {
      return;
    }

    const dimensions = matrixFiltered?.dimensions?.dimensions;
    const columnDefs = getColumnDefinitions(dimensions, perspective, compareColor);
    gridReference.current!.api.setGridOption('columnDefs', columnDefs);
    // other settings
    gridReference.current!.api.autoSizeAllColumns();
  }, [matrixFiltered?.dimensions?.dimensions, perspective, compareColor]);

  return (
    <Box
      component={Paper}
      variant="outlined"
      borderRadius="3px 3px 0 0"
      sx={{ height: dataView === KaeplaDataView.Table ? 500 : '100%' }}
    >
      <ExportDialog
        open={exportDialogOpen}
        setOpen={setExportDialogOpen}
        gridReference={gridReference}
        setDownloading={setDownloading}
        setDownloadSuccess={setDownloadSuccess}
        setDownloadError={setDownloadError}
        gridParameters={gridParameters}
      />
      <GridMenu
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onSearchTermChange={onSearchTermChange}
        handleExportDialogOpen={handleExportDialogOpen}
        dataView={dataView}
        downloading={downloading}
        downloadSuccess={downloadSuccess}
        downloadError={downloadError}
        setDataView={setDataView}
        showSavedGrids={showSavedGrids}
        setShowSavedGrids={setShowSavedGrids}
        currentGridId={currentGridId}
        loadGridState={loadGridState}
        selectedGridSet={selectedGridSet}
        setSelectedGridSet={setSelectedGridSet}
        setTabNumber={setTabNumber}
      />
      {dataView === KaeplaDataView.Grid && (
        <Box sx={{ p: '10px', display: showSavedGrids ? 'block' : 'none' }}>
          <GridSets
            loadGridState={loadGridState}
            addGridStateToGridSet={addGridStateToGridSet}
            selectedGridSet={selectedGridSet}
            setSelectedGridSet={setSelectedGridSet}
            tabNumber={tabNumber}
            setTabNumber={setTabNumber}
          />
        </Box>
      )}
      {dataView === KaeplaDataView.Grid && (
        <Box sx={{ p: '10px', display: application.showFilter ? 'block' : 'none' }}>
          <Filters />
        </Box>
      )}
      <div style={containerStyle}>
        <div style={gridStyle} className="ag-theme-quartz">
          {gridVisible && (
            <AgGridReact
              ref={gridReference}
              rowModelType="serverSide"
              // enableAdvancedFilter={dataView !== DataView.Table && true} // this will take a while to implement on the server side
              defaultColDef={defaultColDefinition}
              autoGroupColumnDef={autoGroupColumnDefinition}
              pivotMode={false}
              alwaysMultiSort={true}
              sideBar={dataView === KaeplaDataView.Table ? [] : sideBar}
              suppressExpandablePivotGroups={true}
              maxConcurrentDatasourceRequests={1}
              maxBlocksInCache={2}
              purgeClosedRowNodes={true}
              onGridReady={onGridReady}
              getContextMenuItems={getContextMenuItems}
              initialState={dataView === KaeplaDataView.Table ? {} : { ...gridState }}
              onGridPreDestroyed={onGridPreDestroyed}
              onStateUpdated={persistGridState}
              onFirstDataRendered={loadColumnState}
            />
          )}
        </div>
      </div>
    </Box>
  );
};

import { Thread } from '@kaepla/types';
import { ChevronRightOutlined, RefreshOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, IconButton, Link, Stack, TextField } from '@mui/material';
import { ColDef, GetRowIdParams, ICellRendererParams } from 'ag-grid-community';
import { prop } from 'rambda';
import { useCallback, useState } from 'react';
import ReactTimeago from 'react-timeago';
import { useRecoilValue } from 'recoil';

import { AgGridReact } from '../../../Frontend/features/AgGrid/index.js';
import { getDuration } from '../../../Frontend/helpers/getDuration.js';
import { useAiThreadQueryFindAll } from '../../../hooks/query/useAiThreadQueryFindAll.js';
import { useProjectQueryFindAll } from '../../../hooks/query/useProjectQueryFindAll.js';
import { Icon } from '../../../primitive/Icon/Icon.js';
import { GetAiThreadListQueryFilterBy } from '../../../repository/AiThread/aiThread.query.js';
import { Pagination } from '../../../repository/base.js';
import { knownUsersState } from '../../../services/recoil/persistent/knownUsersState.js';
import { ColumnCreatedBy } from '../components/ColumnCreatedBy.js';
import { ColumnProject } from '../components/ColumnProject.js';

const BATCH_SIZE = 50;

type Filter = GetAiThreadListQueryFilterBy;

export function AiThreadList() {
  const [filter, setFilter] = useState<Filter>({});
  const [queryParameters, setQueryParameters] = useState<Pagination<Thread>>({
    limit: BATCH_SIZE,
  });

  const knownUsers = useRecoilValue(knownUsersState);

  const projectQuery = useProjectQueryFindAll({
    limit: 1000,
  });

  const threadQuery = useAiThreadQueryFindAll({
    ...queryParameters,
    ...filter,
  });

  const count = threadQuery.data?.length ?? 0;

  const columnDefs: ColDef<Thread>[] = [
    {
      field: 'id',
      colId: 'id',
      headerName: 'ID',
      flex: 1,
      cellRenderer: (parameters: ICellRendererParams<Thread>) => {
        if (!parameters.data) return;
        return (
          <Link href={`/admin/ai-thread/${parameters.data.id}`} underline="hover">
            {parameters.data.id}
          </Link>
        );
      },
    },
    {
      //
      // !!! TODO unify user id names
      //
      // createdBy in import list
      //
      field: 'uid',
      headerName: 'Created By',
      filter: 'agMultiColumnFilter',
      flex: 1,
      cellRenderer: (parameters: ICellRendererParams<Thread>) => {
        return <ColumnCreatedBy createdBy={parameters.data?.uid} />;
      },
    },
    // {
    //   headerName: 'Customer',
    //   flex: 1,
    //   cellRenderer: (parameters: ICellRendererParams<Thread>) => {
    //     return <ColumnCustomerByProject projectId={parameters.data?.projectId} />;
    //   },
    // },
    {
      field: 'projectId',
      headerName: 'Project ID',
      filter: 'agMultiColumnFilter',
      flex: 6,
      cellRenderer: (parameters: ICellRendererParams<Thread>) => {
        return <ColumnProject projectId={parameters.data?.projectId} />;
      },
    },
    {
      headerName: 'Duration',
      cellRenderer: (parameters: ICellRendererParams<Thread>) => {
        if (!parameters.data) return;
        const { createdAt, updatedAt } = parameters.data;
        return <span>{getDuration(createdAt, updatedAt)}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      cellRenderer: (parameters: ICellRendererParams<Thread>) => {
        if (!parameters.data) return;
        return (
          <ReactTimeago date={parameters.data.createdAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
        );
      },
    },
    {
      field: 'id',
      headerName: 'Action',
      cellRenderer: (parameters: ICellRendererParams<Thread>) => {
        if (!parameters.data) return;
        return (
          <IconButton href={`/SyncData/${parameters.data.projectId}/${parameters.data.id}`}>
            <Icon name="OpenProjectIcon" />{' '}
          </IconButton>
        );
      },
    },
  ];

  function handleFilterChange(newFilter: Filter) {
    setFilter({ ...filter, ...newFilter });
  }

  const onLoadMore = useCallback(() => {
    setQueryParameters({
      ...queryParameters,
      limit: (threadQuery.data?.length ?? 0) + BATCH_SIZE,
    });
  }, [threadQuery.data?.length, queryParameters]);

  const getRowId = useCallback((parameters: GetRowIdParams<Thread>) => {
    return parameters.data.id;
  }, []);

  function hasMore(length: number) {
    return length % BATCH_SIZE === 0;
  }

  function getLoadMoreMessage(length: number) {
    if (threadQuery.isLoading) {
      return 'Loading...';
    }
    if (threadQuery.isRefetching) {
      return 'Loading more...';
    }
    const hasMoreData = hasMore(length);
    if (!hasMoreData) {
      return 'No more data';
    }

    return `Load more (${length})`;
  }

  //
  // TODOS
  // - [ ] handle thread page
  //

  return (
    <Stack
      test-id="ai-thread-list"
      spacing={2}
      sx={{
        py: 2,
        display: 'flex',
        height: '80vh',
        flex: 1,
      }}
    >
      <Stack spacing={2} direction={'row'} alignItems={'center'}>
        <LoadingButton
          loading={threadQuery.isRefetching ?? threadQuery.isLoading}
          disabled={threadQuery.isRefetching ?? threadQuery.isLoading}
          loadingPosition="start"
          onClick={() => {
            void threadQuery.refetch();
          }}
          startIcon={<RefreshOutlined />}
          variant="outlined"
        >
          Refresh
        </LoadingButton>
        <LoadingButton
          sx={{
            width: 180,
          }}
          loading={threadQuery.isRefetching ?? threadQuery.isLoading}
          disabled={threadQuery.isRefetching ?? threadQuery.isLoading}
          loadingPosition="end"
          onClick={onLoadMore}
          endIcon={<ChevronRightOutlined />}
          variant="outlined"
        >
          {getLoadMoreMessage(count)}
        </LoadingButton>

        <Autocomplete
          multiple={true}
          //
          // CreatedBy
          //
          size="small"
          sx={{ width: 300 }}
          getOptionKey={(option) => option.uid}
          getOptionLabel={(option) => option.displayName}
          options={knownUsers}
          value={knownUsers.filter((user) => filter.uid?.includes(user.uid))}
          onChange={(_event, createdBy = []) => {
            handleFilterChange({ uid: createdBy.map(prop('uid')) });
          }}
          limitTags={2}
          renderInput={(parameters) => <TextField {...parameters} label="CreatedBy" />}
          renderOption={(properties, option) => {
            const { key, ...optionProperties } = properties;
            return (
              <Box
                key={key}
                component="li"
                // eslint-disable-next-line @typescript-eslint/naming-convention
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...optionProperties}
              >
                <ColumnCreatedBy createdBy={option.uid} />
              </Box>
            );
          }}
        />
        <Autocomplete
          //
          // Project
          //
          // !!! how to update knownProjects, knownCustomers etc
          //
          multiple
          size="small"
          sx={{ width: 300 }}
          loading={projectQuery.isLoading}
          loadingText="Loading projects..."
          options={projectQuery.data ?? []}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          limitTags={2}
          onChange={(_event, project) => {
            console.log('project', project);
            handleFilterChange({ projectId: project.map(prop('id')) });
          }}
          renderInput={(parameters) => <TextField {...parameters} label="Project" />}
        />
      </Stack>
      <AgGridReact<Thread>
        loading={threadQuery.isLoading}
        rowData={threadQuery.data}
        rowModelType="clientSide"
        getRowId={getRowId}
        suppressPaginationPanel={true}
        columnDefs={columnDefs}
      />
    </Stack>
  );
}

import SelectedIcon from '@mui/icons-material/CheckCircleOutline';
import { Card, CardHeader, CardContent, Button, CardActions, Box } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import numbro from 'numbro';
import { KaeplaDimensionDetails, KaeplaPerspective } from '@kaepla/types';

import { useAuth } from '../../../../../AuthReactProvider.js';
import { cleanColumnName } from '../../../../helpers/cleanColumnName.js';

interface AOptions {
  detail: KaeplaDimensionDetails;
  totalCount: number;
  ideal: boolean;
  defaultPerspective?: Partial<KaeplaPerspective>;
  setDefaultPerspective?: (step: Partial<KaeplaPerspective>) => void;
  dimensionType: string;
  desiredDataTypes: string[];
}
export const DimensionCard = ({
  detail,
  totalCount,
  ideal,
  defaultPerspective,
  setDefaultPerspective,
  dimensionType,
  desiredDataTypes,
}: AOptions) => {
  const { kaeplaUser } = useAuth();
  const numberOfRecords = detail.dimensionDetails
    .filter((row) => row.value !== null)
    .reduce((accumulator, row) => {
      const nor = row.numberOfRecords;
      return accumulator + nor;
    }, 0);
  const numberOfRecordsHR = numbro(numberOfRecords).format({
    average: true,
    mantissa: 1,
    optionalMantissa: true,
  });
  const totalCountHR = numbro(totalCount).format({
    average: true,
    mantissa: 1,
    optionalMantissa: true,
  });
  const percent = numberOfRecords / totalCount;
  const percentHR = numbro(percent).format({
    mantissa: 2,
    optionalMantissa: true,
    output: 'percent',
  });

  let fontSize = '100%';
  if (!ideal) {
    fontSize = '80%';
  }

  const dimension = (defaultPerspective as Record<string, string>)?.[dimensionType];

  return (
    <Card
      sx={{
        fontSize,
        opacity: ideal ? 1 : 0.7,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardHeader
        sx={{ pb: 0 }}
        title={<Box sx={{ fontSize }}>{cleanColumnName(detail.columnName)}</Box>}
        subheader={
          <>
            <Box
              component="span"
              sx={{
                fontSize,
                color: desiredDataTypes.includes(detail.dataType) ? 'green' : 'red',
              }}
            >
              {detail.dataType}
            </Box>
            <Box
              component="span"
              sx={{
                fontSize,
                color: numberOfRecords === totalCount ? 'green' : 'red',
                ml: 1,
              }}
            >
              {percentHR}
            </Box>
            <Box>
              {numberOfRecordsHR}/{totalCountHR} records
            </Box>
          </>
        }
        action={dimension === detail.columnName && <SelectedIcon sx={{ color: 'green', m: 1 }} />}
      />
      <CardContent sx={{ pt: 0, pb: 0 }}>
        {/* {numberOfRecordsHR}/{totalCountHR} records */}
      </CardContent>
      {ideal && dimension !== detail.columnName && (
        <CardActions sx={{ m: 1, pt: 0 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              if (!kaeplaUser) return;
              // TODO: solve this type issue
              const newDefaulterspective: Record<string, unknown> = {
                id: 'default',
                name: 'default',
                createdAt: Timestamp.now(),
                createdBy: kaeplaUser.uid,
                ...defaultPerspective,
              };
              newDefaulterspective[dimensionType] = detail.columnName;
              setDefaultPerspective?.(newDefaulterspective);
            }}
          >
            choose
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

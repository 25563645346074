import { KaeplaImport, KaeplaOpsUpdateStatus } from '@kaepla/types';
import { ChevronRightOutlined, RefreshOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Link, Stack, TextField } from '@mui/material';
import { ColDef, GetRowIdParams, ICellRendererParams } from 'ag-grid-community';
import { prop } from 'rambda';
import { useCallback, useState } from 'react';
import ReactTimeago from 'react-timeago';
import { useRecoilValue } from 'recoil';

import { AgGridReact } from '../../../Frontend/features/AgGrid';
import { getDuration } from '../../../Frontend/helpers/getDuration';
import { dataImportTimeToGo } from '../../../Frontend/Screens/SyncData/helpers/dataIMportTimeToGo';
import { useImportQueryFindAll, useProjectQueryFindAll } from '../../../hooks/query';
import { Icon } from '../../../primitive/Icon/Icon';
import { Pagination } from '../../../repository/base';
import { GetImportListQueryFilterBy } from '../../../repository/Import';
import { knownCustomersState } from '../../../services/recoil/nonpersistent/knownCustomersState';
import { knownResellersState } from '../../../services/recoil/nonpersistent/knownResellersState';
import { knownUsersState } from '../../../services/recoil/persistent/knownUsersState';
import { ColumnCreatedBy } from '../components/ColumnCreatedBy';
import { ColumnCustomer } from '../components/ColumnCustomer';
import { ColumnProject } from '../components/ColumnProject';

const BATCH_SIZE = 50;

type Filter = GetImportListQueryFilterBy;

export function ImportList() {
  const [filter, setFilter] = useState<Filter>({});
  const [queryParameters, setQueryParameters] = useState<Pagination<KaeplaImport>>({
    limit: BATCH_SIZE,
  });
  const knownUsers = useRecoilValue(knownUsersState);
  const knownCustomers = useRecoilValue(knownCustomersState);
  const knownResellers = useRecoilValue(knownResellersState);

  function handleFilterChange(newFilter: Filter) {
    setFilter({ ...filter, ...newFilter });
  }

  const importsQuery = useImportQueryFindAll(
    {
      ...queryParameters,
      ...filter,
    },
    {
      refetchInterval: 10_000,
    },
  );

  const projectQuery = useProjectQueryFindAll({
    limit: 1000,
  });

  const columnDefs: ColDef<KaeplaImport>[] = [
    {
      field: 'id',
      colId: 'id',
      headerName: 'ID',
      width: 100,
      cellRenderer: (parameters: ICellRendererParams<KaeplaImport>) => {
        if (!parameters.data) return;
        return (
          <Link
            href={`/SyncData/${parameters.data.projectId}/${parameters.data.id}`}
            underline="hover"
          >
            {parameters.data.id}
          </Link>
        );
      },
    },
    {
      field: 'updateStatus',
      headerName: 'Status',
      filter: 'agMultiColumnFilter',
      // width: 130,
      cellRenderer: (parameters: ICellRendererParams<KaeplaImport>) => {
        if (!parameters.data) return;
        switch (parameters.data.updateStatus) {
          case KaeplaOpsUpdateStatus.finished: {
            return <Icon name="SucceededIcon" center={true} color="success" />;
          }
          case KaeplaOpsUpdateStatus.failed:
          case KaeplaOpsUpdateStatus.cancelRequested:
          case KaeplaOpsUpdateStatus.canceled: {
            return (
              <Stack direction="row" spacing={1} alignItems={'center'}>
                <Icon
                  name="FailedIcon"
                  center={true}
                  titleAccess={parameters.data.failureReason}
                  color="error"
                />
                <span title={parameters.data.failureReason}>{parameters.data.failureReason}</span>
              </Stack>
            );
          }
          case KaeplaOpsUpdateStatus.started:
          case KaeplaOpsUpdateStatus.initial:
          case KaeplaOpsUpdateStatus.waiting: {
            return (
              <Icon
                name="PendingActionsOutlined"
                titleAccess={parameters.data.updateStatus}
                center={true}
                color="info"
              />
            );
          }
          default: {
            return parameters.data.updateStatus;
          }
        }
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      filter: 'agMultiColumnFilter',
      flex: 1,
      cellRenderer: (parameters: ICellRendererParams<KaeplaImport>) => {
        return <ColumnCreatedBy createdBy={parameters.data?.createdBy} />;
      },
    },
    {
      field: 'customerId',
      headerName: 'Customer',
      filter: 'agMultiColumnFilter',
      flex: 3,
      cellRenderer: (parameters: ICellRendererParams<KaeplaImport>) => {
        if (!parameters.data) return;
        return <ColumnCustomer customerId={parameters.data.customerId} />;
      },
    },
    {
      field: 'projectId',
      headerName: 'Project',
      filter: 'agMultiColumnFilter',
      flex: 3,
      cellRenderer: (parameters: ICellRendererParams<KaeplaImport>) => {
        return <ColumnProject projectId={parameters.data?.projectId} />;
      },
    },
    {
      field: 'resellerId',
      headerName: 'Reseller ID',
      filter: 'agMultiColumnFilter',
      cellRenderer: (parameters: ICellRendererParams<KaeplaImport>) => {
        const reseller = knownResellers.find((k) => k.id === parameters.data?.resellerId);
        if (reseller) {
          return reseller.name;
        }
        //
        return parameters.data?.resellerId;
      },
    },
    {
      headerName: 'Duration',
      flex: 2,
      cellRenderer: (parameters: ICellRendererParams<KaeplaImport>) => {
        if (!parameters.data) return;
        const { startedAt, updatedAt, endedAt } = parameters.data;
        const end = endedAt ?? updatedAt;
        if (!end) return;
        const importProject = projectQuery.data?.find((k) => k.id === parameters.data?.projectId);
        const title = dataImportTimeToGo(parameters.data, importProject);
        return <span title={title}>{getDuration(startedAt, end)}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      cellRenderer: (parameters: ICellRendererParams<KaeplaImport>) => {
        if (!parameters.data) return;
        return (
          <ReactTimeago date={parameters.data.createdAt.toDate()} max={Number.MAX_SAFE_INTEGER} />
        );
      },
    },
    {
      field: 'ingestedRecordsCount',
      headerName: 'Ingested Records',
    },
  ];

  const updateStatusKeys = Object.keys(KaeplaOpsUpdateStatus);

  const onLoadMore = useCallback(() => {
    setQueryParameters({
      ...queryParameters,
      limit: (importsQuery.data?.length ?? 0) + BATCH_SIZE,
    });
  }, [importsQuery.data?.length, queryParameters]);

  const getRowId = useCallback((parameters: GetRowIdParams<KaeplaImport>) => {
    return parameters.data.id;
  }, []);

  return (
    <Stack
      spacing={2}
      sx={{
        py: 2,
        display: 'flex',
        height: '80vh',
        flex: 1,
      }}
    >
      <Stack spacing={2} direction={'row'} alignItems={'center'}>
        <LoadingButton
          loading={importsQuery.isRefetching ?? importsQuery.isLoading}
          disabled={importsQuery.isRefetching ?? importsQuery.isLoading}
          loadingPosition="start"
          onClick={() => {
            void importsQuery.refetch();
          }}
          startIcon={<RefreshOutlined />}
          variant="outlined"
        >
          Refresh
        </LoadingButton>
        <LoadingButton
          loading={importsQuery.isRefetching ?? importsQuery.isLoading}
          disabled={importsQuery.isRefetching ?? importsQuery.isLoading}
          loadingPosition="end"
          onClick={onLoadMore}
          endIcon={<ChevronRightOutlined />}
          variant="outlined"
        >
          Load more
        </LoadingButton>
        <Autocomplete
          //
          // UpdateStatus
          //
          multiple
          size="small"
          sx={{ width: 300 }}
          options={updateStatusKeys as KaeplaOpsUpdateStatus[]}
          onChange={(_event, updateStatus) => {
            handleFilterChange({ updateStatus });
          }}
          limitTags={2}
          renderInput={(parameters) => <TextField {...parameters} label="Update Status" />}
        />
        <Autocomplete
          //
          // CreatedBy
          //
          size="small"
          sx={{ width: 300 }}
          getOptionKey={(option) => option.uid}
          getOptionLabel={(option) => option.displayName}
          options={knownUsers}
          onChange={(_event, createdBy) => {
            handleFilterChange({ createdBy: createdBy?.displayName });
          }}
          limitTags={2}
          renderInput={(parameters) => <TextField {...parameters} label="CreatedBy" />}
          renderOption={(properties, option) => {
            const { key, ...optionProperties } = properties;
            return (
              <Box
                key={key}
                component="li"
                // eslint-disable-next-line @typescript-eslint/naming-convention
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...optionProperties}
              >
                <ColumnCreatedBy createdBy={option.uid} />
              </Box>
            );
          }}
        />

        <Autocomplete
          //
          // Customers
          //
          // !!! how to update knownProjects, knownCustomers etc
          //
          multiple
          size="small"
          sx={{ width: 300 }}
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id}
          limitTags={2}
          options={knownCustomers}
          onChange={(_event, customer) => {
            handleFilterChange({ customerId: customer.map(prop('id')) });
          }}
          renderInput={(parameters) => <TextField {...parameters} label="Customers" />}
          renderOption={(properties, option) => {
            const { key, ...optionProperties } = properties;
            return (
              <Box
                key={key}
                component="li"
                // eslint-disable-next-line @typescript-eslint/naming-convention
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...optionProperties}
              >
                <ColumnCustomer key={key} customerId={option.id} />
              </Box>
            );
          }}
        />
        <Autocomplete
          //
          // Project
          //
          // !!! how to update knownProjects, knownCustomers etc
          //
          multiple
          size="small"
          sx={{ width: 300 }}
          loading={projectQuery.isLoading}
          loadingText="Loading projects..."
          options={projectQuery.data ?? []}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          limitTags={2}
          onChange={(_event, project) => {
            handleFilterChange({ projectId: project.map(prop('id')) });
          }}
          renderInput={(parameters) => <TextField {...parameters} label="Project" />}
        />
      </Stack>
      <AgGridReact<KaeplaImport>
        loading={importsQuery.isLoading}
        rowData={importsQuery.data}
        rowModelType="clientSide"
        getRowId={getRowId}
        suppressPaginationPanel={true}
        columnDefs={columnDefs}
      />
    </Stack>
  );
}

import { MatrixTimeseriesEntry, MatrixTimeseries } from '@kaepla/types';
import { atom } from 'recoil';

// TODO: have a default time series so the animation is smoother initially?
const defaultEntries: MatrixTimeseriesEntry[] = [];

const defaultTimeSeriesState: MatrixTimeseries = {
  timeseries: defaultEntries,
  job: { ms: 0, bytes: 0, cached: true },
};

export const timeSeriesState = atom<MatrixTimeseries>({
  key: 'timeSeries',
  default: defaultTimeSeriesState,
});

import EditIcon from '@mui/icons-material/EditOutlined';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { KaeplaDimension } from '@kaepla/types';

import {
  matrixDataColor,
  matrixDataColorLight,
  simulationDataColor,
  simulationDataColorLight,
  simulationPreviewDataColor,
  simulationPreviewDataColorLight,
} from '../../../defaults';

import { TimeSliceValue } from './TimeSliceValue.js';

interface Options {
  valueDimension?: KaeplaDimension;
  value?: number;
  simulation?: boolean;
  align?: string;
  editable?: boolean;
  full?: boolean;
  isPrecalculated?: boolean;
  isAbsolute?: boolean;
}

export const TimeSliceValueAndLabel = ({
  valueDimension,
  value,
  simulation,
  align,
  editable,
  full,
  isPrecalculated,
  isAbsolute,
}: Options) => {
  const [canEdit, setCanEdit] = useState(false);

  let textAlign = 'center';
  if (align) {
    textAlign = align;
  }

  let borderColor = matrixDataColor;
  let color = matrixDataColorLight;
  if (simulation) {
    borderColor = simulationDataColor;
    color = simulationDataColorLight;
  }
  if (isPrecalculated) {
    borderColor = simulationPreviewDataColor;
    color = simulationPreviewDataColorLight;
  }

  if (!valueDimension || !value) {
    return (
      <Typography sx={{ ml: 3, textAlign }} variant="caption">
        n/a
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        border: '0px solid red',
        textAlign,
        cursor: editable ? 'pointer' : 'default',
        whiteSpace: 'nowrap',
      }}
      onMouseEnter={() => {
        if (!editable) return;
        setCanEdit(true);
      }}
      onMouseLeave={() => {
        if (!editable) return;
        setCanEdit(false);
      }}
    >
      <Box
        component="span"
        display="inline-block"
        border={1}
        borderRadius={6}
        width={12}
        height={12}
        bgcolor={color}
        borderColor={borderColor}
        mr={1}
      />
      <Box component="span" sx={{ border: '0px solid green', textAlign: 'center' }}>
        <TimeSliceValue valueDimension={valueDimension} value={value} isAbsolute={isAbsolute} />
        {full && (
          <Typography sx={{ ml: 3 }} variant="caption">
            {value}
          </Typography>
        )}
        {canEdit && <EditIcon sx={{ ml: 1, fontSize: 14 }} />}
      </Box>
    </Box>
  );
};

import {
  AsyncFetchListValuesResult,
  BooleanField,
  ListItems,
  SimpleField,
} from '@react-awesome-query-builder/mui';
import {
  KaeplaDimension,
  KaeplaProject,
  KaeplaApiParameters,
  KaeplaQueryType,
} from '@kaepla/types';

import { searchDimensionValues } from '../../../../services/api/searchDimensionValues.js';
import { cleanColumnName } from '../../../helpers/cleanColumnName.js';

import { typeMap } from './typeMap.js';


interface Options {
  dimension: KaeplaDimension;
  project: KaeplaProject;
  currentScopePath: string[] | undefined;
  uid: string;
}

export const getFieldSetting = ({ dimension, project, currentScopePath, uid }: Options) => {
  const fieldName = dimension.columnName;

  const getValuesForDimension = async (search: string | null, offset: number) => {
    const result: AsyncFetchListValuesResult = {
      values: [],
      hasMore: true,
    };

    if (!search) {
      return result;
    }

    const parameters: KaeplaApiParameters = {
      q: 'dimensionValueSearch' as KaeplaQueryType,
      projectId: project.id,
      dimension: fieldName,
      searchKey: search,
      limit: 5,
      offset,
    };

    parameters.p = currentScopePath;

    const searchResult = await searchDimensionValues({ ...parameters, uid });
    const resultData = searchResult.data;
    const response = (resultData as Record<string, Record<string, unknown>>).response;
    result.values = response.values as ListItems;
    result.hasMore = response.hasMore as boolean;
    return result;
  };

  switch (dimension.dataType) {
    case 'STRING': {
      return {
        label: cleanColumnName(fieldName),
        type: typeMap[dimension.dataType],
        fieldSettings: {
          asyncFetch: getValuesForDimension,
          useAsyncSearch: true,
          useLoadMore: true,
          forceAsyncSearch: false,
          allowCustomValues: false,
        },
        valueSources: ['value'],
      } as SimpleField;
    }
    case 'Date': {
      return {
        label: cleanColumnName(fieldName),
        type: typeMap[dimension.dataType],
        valueSources: ['value'],
      } as SimpleField;
    }
    case 'FLOAT64': {
      return {
        label: cleanColumnName(fieldName),
        type: typeMap[dimension.dataType],
        valueSources: ['value'],
      } as SimpleField;
    }
    case 'NUMERIC': {
      return {
        label: cleanColumnName(fieldName),
        type: typeMap[dimension.dataType],
        valueSources: ['value'],
      } as SimpleField;
    }
    case 'BIGNUMERIC': {
      return {
        label: cleanColumnName(fieldName),
        type: typeMap[dimension.dataType],
        valueSources: ['value'],
        preferWidgets: ['number'],
      } as SimpleField;
    }
    case 'BOOL': {
      return {
        label: cleanColumnName(fieldName),
        type: typeMap[dimension.dataType],
        valueSources: ['value'],
        operators: ['equal'],
      } as BooleanField;
    }
    default: {
      return {
        label: cleanColumnName(fieldName),
        type: typeMap[dimension.dataType],
        valueSources: ['value'],
      } as SimpleField;
    }
  }
};

import DeleteIcon from '@mui/icons-material/Delete';
import PerspectivesIcon from '@mui/icons-material/QueryStatsOutlined';
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import {
  KaeplaTargets,
  KaeplaEventType,
  KaeplaEventEffect,
  KaeplaFunctionGroup,
  KaeplaDataOperation,
} from '@kaepla/types';

import { useAuth } from '../../../AuthReactProvider.js';
import { getKaeplaTargets } from '../../../services/api/getKaeplaTargets.js';
import { createEvent } from '../../../services/firestore/createEvent.js';
import { deleteTargets } from '../../../services/firestore/deleteTargets.js';
import { perspectiveState } from '../../../services/recoil/nonpersistent/perspectiveState.js';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { scopePathsState } from '../../../services/recoil/nonpersistent/scopePathsState.js';
import { simulationState } from '../../../services/recoil/nonpersistent/simulationState.js';
import { snapShotState } from '../../../services/recoil/nonpersistent/snapshotState.js';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState.js';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { knownUsersState } from '../../../services/recoil/persistent/knownUsersState.js';
import { UserAvatar } from '../../features/UserAvatar.js';
import { cleanColumnName } from '../../helpers/cleanColumnName';
import { HumanReadableTimestampType, convertTimestamp } from '../../helpers/convertTimestamp.js';
import { scopePathSimplified } from '../../helpers/scopePathSimplified.js';
import { perspectivesColor } from '../defaults.js';

import { TargetsDeleteDialog } from './TargetsDeleteDialog.js';


interface Options {
  targets: KaeplaTargets;
  own?: boolean;
}

export const TargetsCard = ({ targets, own }: Options) => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  // get
  const project = useRecoilValue(projectState);
  const knownUsers = useRecoilValue(knownUsersState);
  const owner = knownUsers.find((user) => user.uid === targets.createdBy);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  // get & set
  const [currentScopePath, setCurrentScopePath] = useRecoilState(currentScopePathState);
  const isCurrent = targets.scopePathStringified === JSON.stringify(currentScopePath);
  const [scopePaths, setScopePaths] = useRecoilState(scopePathsState);
  // reset
  const resetPerspective = useResetRecoilState(perspectiveState);
  const resetSimulation = useResetRecoilState(simulationState);
  const resetSnapshot = useResetRecoilState(snapShotState);
  // local
  const [hover, setHover] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const deleteTargetsCallback = () => {
    const parameters = {
      projectId: project.id,
      targetsId: targets.id,
      deleteTargets: true,
    };

    void getKaeplaTargets({
      params: parameters,
    });
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.TARGETS_DELETE_TARGETS,
      effect: KaeplaEventEffect.PROJECT_TARGETS_CHANGE,
      functionGroup: KaeplaFunctionGroup.TARGETS,
      operation: KaeplaDataOperation.DELETE,
      project,
      scopePath: targets.scopePath,
    });
    void deleteTargets({ project, targets });
  };

  return (
    <>
      <Card
        data-testid="TargetsCard"
        elevation={hover || isCurrent ? 8 : 2}
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          setCurrentScopePath(targets.scopePath);
          const newScopePaths = { ...scopePaths };
          newScopePaths[targets.projectId] = targets.scopePath;
          setScopePaths(newScopePaths);

          void createEvent({
            uid: kaeplaUser?.uid,
            eventType: KaeplaEventType.TARGETS_SELECT_TARGETS,
            functionGroup: KaeplaFunctionGroup.TARGETS,
            operation: KaeplaDataOperation.READ,
            project,
            scopePath: targets.scopePath,
          });
          navigate(`/Targets/${project.id}/${targets.id}`);
        }}
        onFocus={() => {
          setHover(true);
        }}
        onBlur={() => {
          setHover(false);
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <CardHeader
          title={`${cleanColumnName(targets?.dimension ?? 'New')} Targets`}
          avatar={owner && <UserAvatar user={owner} />}
          subheader={
            targets.updatedAt
              ? `updated ${convertTimestamp(targets.updatedAt, HumanReadableTimestampType.timeago)}`
              : 'did not run yet'
          }
        />
        <CardContent sx={{ minHeight: 150 }}>
          <PerspectivesIcon
            sx={{ color: perspectivesColor, fontSize: 14, mr: 0.5, verticalAlign: 'middle' }}
          />
          <Typography
            variant="caption"
            component="span"
            onClick={(event) => {
              event.stopPropagation();
              setCurrentScopePath(targets.scopePath);
              const newScopePaths = { ...scopePaths };
              newScopePaths[targets.projectId] = targets.scopePath;
              setScopePaths(newScopePaths);

              resetSimulation();
              resetSnapshot();
              resetPerspective();

              void createEvent({
                uid: kaeplaUser?.uid,
                eventType: KaeplaEventType.ASSIGNMENT_SELECT_ASSIGNMENT,
                functionGroup: KaeplaFunctionGroup.ASSIGNMENTS,
                operation: KaeplaDataOperation.READ,
                project,
                scopePath: targets.scopePath,
              });
              navigate('/Perspective');
            }}
          >
            {[project.name, ...scopePathSimplified(targets.scopePath)].join(' ➔ ')}
          </Typography>
          {targets.isBeingUpdated && (
            <Alert sx={{ mt: 1 }} icon={false} severity="info">
              Targets are being prepared
            </Alert>
          )}
        </CardContent>
        {(own === true || kaeplaAssignment) && (
          <Tooltip title={targets.id}>
            <CardActions sx={{ m: 1, pt: 0, justifyContent: 'flex-end' }}>
              <IconButton
                color="primary"
                disabled={targets.isBeingUpdated || (!own && !kaeplaAssignment)}
                onClick={(event) => {
                  event.stopPropagation();
                  setOpenDeleteDialog(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Tooltip>
        )}
      </Card>
      <TargetsDeleteDialog
        targets={targets}
        open={openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        deleteTargets={deleteTargetsCallback}
      />
    </>
  );
};

import {
  KaeplaApiParameters,
  KaeplaOperation,
  KaeplaOperationType,
  KaeplaQueryType,
  MatrixData,
  MatrixJob,
} from '@kaepla/types';
import {
  Box,
  Chip,
  Divider,
  Grid2 as Grid,
  LinearProgress,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import { blueGrey, deepOrange, green, purple } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { getFromKaepla } from '../../../services/api/getFromKaepla';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { Layout } from '../../Layout/Layout';
import { BQJobInfo } from '../../features/BQJobInfo';
import { ProjectLoading } from '../../features/ProjectLoading.js';
import { ProjectInfoSmall } from '../Perspectives/features/ProjectSummary/features/ProjectInfoSmall.js';

import { OperationCard } from './OperationCard.js';

export const operationTypeColors = {
  mapping: deepOrange[100],
  calculation: green[100],
  sqlFunction: purple[100],
  pivotMapping: blueGrey[100],
};

export const Operations = () => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const [loading, setLoading] = useState(true);
  const [job, setJob] = useState<MatrixJob>();
  const [operations, setOperations] = useState<KaeplaOperation[]>([]);
  const [mappings, setMappings] = useState<KaeplaOperation[]>([]);
  const [calculations, setCalculations] = useState<KaeplaOperation[]>([]);
  const [sqlFunctions, setSqlFunctions] = useState<KaeplaOperation[]>([]);

  useEffect(() => {
    if (!project?.id) return;
    const parameters: KaeplaApiParameters = {
      q: 'operations' as KaeplaQueryType,
      projectId: project.id,
      s: 'Operations',
    };

    getFromKaepla({
      callBack: (apiResponse) => {
        if (!apiResponse) return;
        const response = apiResponse.response as MatrixData;
        setJob(response.job);
        const _operations = response.rows as unknown as KaeplaOperation[];
        setOperations(_operations);
        setMappings(_operations.filter((o) => o.type === KaeplaOperationType.mapping));
        setCalculations(_operations.filter((o) => o.type === KaeplaOperationType.calculation));
        setSqlFunctions(_operations.filter((o) => o.type === KaeplaOperationType.sqlFunction));
      },
      errorCallBack: (_error) => {
        // console.log(error);
        setLoading(false);
      },
      setLoading,
      params: parameters,
      uid: kaeplaUser?.uid,
    });
  }, [kaeplaUser?.uid, project?.id]);

  if (!project || project.matrixUnavailable) {
    return <ProjectLoading />;
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 4, md: 3 }}>
                <ProjectInfoSmall title={project.name} />
              </Grid>
              <Grid size={2}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid
                size={{ xs: 12, sm: 7, md: 6 }}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <Typography variant="h6">Calculations & Mappings</Typography>
                <Typography variant="body1">
                  Mappings, calculations and custom functions. Mappings help to aggregate and
                  visualize data correctly. Calculations and functions are key to run simulations.
                </Typography>
                <Toolbar disableGutters variant="dense">
                  {job && (
                    <Box sx={{ mr: 1 }}>
                      <BQJobInfo job={job} />
                    </Box>
                  )}
                  {operations.length > 0 && (
                    <Chip sx={{ mr: 1 }} label={`total ${operations.length} Operations`} />
                  )}
                  {operations.length > 0 && (
                    <Chip
                      sx={{ mr: 1, bgcolor: operationTypeColors.mapping }}
                      label={`${mappings.length} Mappings`}
                    />
                  )}
                  {operations.length > 0 && (
                    <Chip
                      sx={{ mr: 1, bgcolor: operationTypeColors.calculation }}
                      label={`${calculations.length} Calculations`}
                    />
                  )}
                  {sqlFunctions.length > 0 && (
                    <Chip
                      sx={{ mr: 1, bgcolor: operationTypeColors.sqlFunction }}
                      label={`${sqlFunctions.length} Functions`}
                    />
                  )}
                </Toolbar>
              </Grid>
            </Grid>
          </Paper>
          {loading && <LinearProgress />}
        </Grid>
        {operations.map((operation) => (
          <Grid key={operation.resultField} size={{ xs: 12, sm: 6, md: 4 }}>
            <OperationCard operation={operation} />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

import {
  KaeplaCustomer,
  KaeplaDataOperation,
  KaeplaEventType,
  KaeplaFunctionGroup,
  KaeplaReseller,
} from '@kaepla/types';
import { Box, Grid2 as Grid, LinearProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { createEvent } from '../../../services/firestore/createEvent.js';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState.js';
import { knownCustomersState } from '../../../services/recoil/nonpersistent/knownCustomersState.js';
import { knownResellersState } from '../../../services/recoil/nonpersistent/knownResellersState.js';
import { Layout } from '../../Layout/Layout.js';

import { CreateCustomerFab } from './features/CreateCustomerFab.js';
import { CustomerCard } from './features/CustomerCard.js';
import { KaeplaAdminResellerSwitch } from './KaeplaAdminResellerSwitch.js';

export const Customers = () => {
  const { kaeplaUser } = useAuth();
  const { resellerId } = useParams();
  const app = useRecoilValue(applicationState);
  const knownCustomers = useRecoilValue(knownCustomersState);
  let shownCustomers: KaeplaCustomer[] = [...knownCustomers];
  const knownResellers = useRecoilValue(knownResellersState);
  let reseller: KaeplaReseller | undefined;
  if (knownResellers.length > 0) {
    reseller = knownResellers.filter((k) => !!k).find((r) => r.id === resellerId);
    if (!reseller) {
      reseller = knownResellers[0];
    }
    if (reseller) {
      shownCustomers = knownCustomers.filter((c) => c.resellerId === reseller?.id);
    }
  }

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!app.customersLoaded) return;
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.CUSTOMERS_ENTER_CUSTOMERS_OVERVIEW,
      functionGroup: KaeplaFunctionGroup.CUSTOMER,
      operation: KaeplaDataOperation.READ,
    });
  }, [app.customersLoaded, kaeplaUser?.uid]);

  if (!app.customersLoaded) {
    return (
      <Layout>
        <LinearProgress />
      </Layout>
    );
  }

  return (
    <Layout>
      <Box>
        <Grid container spacing={1}>
          <Grid size={8}>
            <Typography variant="h4">Customers</Typography>
          </Grid>
          <Grid size={4} container justifyContent="flex-end">
            {reseller && <KaeplaAdminResellerSwitch resellerId={reseller.id} />}
          </Grid>
          {shownCustomers.map((customer) => (
            <CustomerCard key={customer.id} customer={customer} />
          ))}
        </Grid>
        {reseller && <CreateCustomerFab resellerId={reseller.id} />}
      </Box>
    </Layout>
  );
};

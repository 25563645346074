import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  KaeplaEventType,
  KaeplaFunctionGroup,
  KaeplaDataOperation,
  KaeplaApiParameters,
  KaeplaQueryType,
  MatrixSummary,
  MatrixTimeSlice,
  KaeplaSimulation,
} from '@kaepla/types';

import { useAuth } from '../../../AuthReactProvider.js';
import { useUserPreferences } from '../../../UserPreferencesProvider';
import { getFromKaepla } from '../../../services/api/getFromKaepla';
import { getKaeplaTargets } from '../../../services/api/getKaeplaTargets';
import { createEvent } from '../../../services/firestore/createEvent';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState';
import { dataViewState } from '../../../services/recoil/nonpersistent/dataViewState.js';
import { matrixFilteredState } from '../../../services/recoil/nonpersistent/matrixFilteredState';
import { matrixLoadingState } from '../../../services/recoil/nonpersistent/matrixLoadingState';
import { perspectiveState } from '../../../services/recoil/nonpersistent/perspectiveState.js';
import { perspectiveTargetFiguresState } from '../../../services/recoil/nonpersistent/perspectiveTargetFigures';
import { perspectivesState } from '../../../services/recoil/nonpersistent/perspectivesState';
import { projectState } from '../../../services/recoil/nonpersistent/projectState.js';
import { projectTargetsState } from '../../../services/recoil/nonpersistent/projectTargetsState';
import { simulationState } from '../../../services/recoil/nonpersistent/simulationState';
import { simulationsState } from '../../../services/recoil/nonpersistent/simulationsState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { timeSliceState } from '../../../services/recoil/persistent/timeSliceState';
import { KaeplaDataView } from '../../../typings/KaeplaDataView.js';
import { Layout } from '../../Layout/Layout.js';
import { ProjectLoading } from '../../features/ProjectLoading.js';

import { DataViewGrid } from './dataViews/DataViewGrid.js';
import { WaitFirstSyncToComplete } from './Wizard/WaitFirstSyncToComplete.js';
import { PerspectiveWizard } from './Wizard/PerspectiveWizard.js';
import { CreateDummyMatrix } from './Wizard/CreateDummyMatrix.js';
import { DefaultPerspective } from './DefaultPerspective.js';
import { CustomPerspective } from './CustomPerspective.js';


export const Perspective = () => {
  const { kaeplaUser } = useAuth();
  const { getPreferences } = useUserPreferences();
  // get
  const app = useRecoilValue(applicationState);
  const project = useRecoilValue(projectState);
  const dataView = useRecoilValue(dataViewState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const perspectives = useRecoilValue(perspectivesState);
  const simulations = useRecoilValue(simulationsState);
  const projectTargets = useRecoilValue(projectTargetsState);
  // set
  const setTimeSlice = useSetRecoilState(timeSliceState);
  const setMatrixFiltered = useSetRecoilState(matrixFilteredState);
  const [matrixLoading, setMatrixLoading] = useRecoilState(matrixLoadingState);
  const setPerspectiveTargetFigures = useSetRecoilState(perspectiveTargetFiguresState);
  // get & set
  const [simulation, setSimulation] = useRecoilState(simulationState);
  const [perspective, setPerspective] = useRecoilState(perspectiveState);
  // reset
  const resetPerspective = useResetRecoilState(perspectiveState);
  const resetSimulation = useResetRecoilState(simulationState);
  // local
  const [perspectiveLoading, setPerspectiveLoading] = useState(true);

  useEffect(() => {
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PERSPECTIVES_VIEW_PERSPECTIVE,
      functionGroup: KaeplaFunctionGroup.PERSPECTIVES,
      operation: KaeplaDataOperation.READ,
      scopePath: currentScopePath,
      project,
      perspectiveId: perspective?.id,
    });
  }, [currentScopePath, kaeplaUser?.uid, perspective?.id, project]);

  useEffect(() => {
    if (!project?.id) return;
    if (perspectiveLoading) return;
    if (project?.matrixUnavailable) return;

    const parameters: KaeplaApiParameters = {
      q: 'summary' as KaeplaQueryType,
      p: currentScopePath,
      projectId: project.id,
      s: 'Dashboard',
    };

    getFromKaepla({
      callBack: (apiResponse) => {
        // console.log("->", apiResponse);
        const response = apiResponse.response as MatrixSummary;
        setMatrixFiltered((_matrixFiltered) => ({ ..._matrixFiltered, ...response }));
      },
      setLoading: setMatrixLoading,
      params: parameters,
      uid: kaeplaUser?.uid,
    });
  }, [
    currentScopePath,
    kaeplaUser?.uid,
    perspectiveLoading,
    project?.id,
    project?.matrixUnavailable,
    setMatrixFiltered,
    setMatrixLoading,
  ]);

  // this effect runs if perspective is changed from outside
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    if (!app.initialized) return;
    if (!currentScopePath) return;
    // if (!project?.totalDimensionsCount) {
    //   // we're about to use the setup wizard
    //   setPerspectiveLoading(false);
    //   return;
    // }
    if (project?.matrixUnavailable) {
      setPerspectiveLoading(false);
      return;
    }

    // default
    let perspectiveFromState = project.defaultPerspective;
    // from preferences
    const preferences = getPreferences();
    if (preferences?.lastPerspectiveId) {
      perspectiveFromState = perspectives?.find((p) => p.id === preferences.lastPerspectiveId);
    }

    if (perspectiveFromState) {
      setPerspective(perspectiveFromState);
    } else if (project.defaultPerspective) {
      setPerspective(project.defaultPerspective);
    } else {
      resetPerspective();
    }

    // get target figures
    if (perspectiveFromState?.valueDimension && projectTargets) {
      const targetsForPerspective = projectTargets
        .filter((targets) => targets.dimension === perspectiveFromState?.valueDimension)
        .filter((targets) => targets.scopePathStringified === JSON.stringify(currentScopePath));
      // how do we deal with this if there are more than one?
      // will there be a dropdown to select from them like with simulations?
      // will we prevent having more than one set of target figures per scope and valueDimension?
      if (targetsForPerspective.length > 0) {
        const targets = targetsForPerspective[0];

        void getKaeplaTargets({
          callBack: (apiResponse) => {
            // const result = apiResponse;
            const _targetFigures = apiResponse.rows;
            if (_targetFigures) {
              const targetFiguresSorted = _targetFigures.sort((a, b) =>
                a.validAsOf.localeCompare(b.validAsOf),
              );
              setPerspectiveTargetFigures(targetFiguresSorted);
            }
          },
          params: {
            projectId: project.id,
            targetsId: targets.id,
          },
        });
      } else {
        setPerspectiveTargetFigures([]);
      }
    }

    if (preferences?.lastScopeTimeSlice) {
      setTimeSlice(preferences.lastScopeTimeSlice as MatrixTimeSlice);
    }
    let simulationFromState: KaeplaSimulation | undefined;
    if (preferences?.lastSimulationId) {
      simulationFromState = simulations?.find((s) => s.id === preferences.lastSimulationId);
    }

    if (simulation?.id && !simulationFromState) {
      // keep the selected simulation
    } else if (simulationFromState) {
      setSimulation(simulationFromState);
    } else {
      resetSimulation();
    }

    setPerspectiveLoading(false);
    return () => {
      setPerspectiveLoading(true);
    };
  }, [
    app.initialized,
    currentScopePath,
    getPreferences,
    kaeplaUser?.uid,
    perspectives,
    project?.defaultPerspective,
    project?.id,
    project?.matrixUnavailable,
    project?.totalDimensionsCount,
    projectTargets,
    resetPerspective,
    resetSimulation,
    setPerspective,
    setPerspectiveTargetFigures,
    setSimulation,
    setTimeSlice,
    simulation?.id,
    simulations,
  ]);

  if (!project?.id) {
    return <ProjectLoading />;
  }

  if (project?.importConfigurationId && project?.matrixUnavailable) {
    return (
      <Layout>
        <WaitFirstSyncToComplete />
      </Layout>
    );
  }

  if (!project?.importConfigurationId && project?.matrixUnavailable) {
    return (
      <Layout>
        <CreateDummyMatrix />
      </Layout>
    );
  }

  if (perspectiveLoading || matrixLoading) {
    return <ProjectLoading />;
  }

  if (!project?.initializedAt) {
    return (
      <Layout hasScopeNavigation={!project?.matrixUnavailable}>
        <PerspectiveWizard />
      </Layout>
    );
  }

  if (dataView === KaeplaDataView.Grid) {
    return <DataViewGrid />;
  }

  // if (kaeplaUser?.email.endsWith('@kaepla.atrigam.com')) {
  //   return <AiAssistantPerspective />;
  // }

  if (perspective?.id && perspective.id !== 'default') {
    return <CustomPerspective perspective={perspective} />;
  }

  return <DefaultPerspective />;
};

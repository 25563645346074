import { KaeplaCustomer } from '@kaepla/types';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import RenameIcon from '@mui/icons-material/EditOutlined';
import ConnectorIcon from '@mui/icons-material/LinkOutlined';
import { Menu } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

interface Options {
  anchorEl: HTMLElement | null;
  open: boolean;
  canRename: boolean;
  canDelete: boolean;
  onClose: () => void;
  handleClickOpenDelete: () => void;
  handleClickOpenRename: () => void;
  customer: KaeplaCustomer;
}
export const CardMenu = ({
  anchorEl,
  open,
  canRename,
  canDelete,
  onClose,
  handleClickOpenDelete,
  handleClickOpenRename,
  customer,
}: Options) => {
  const navigate = useNavigate();
  if (!open) return null;

  return (
    <Menu anchorEl={anchorEl} open onClose={onClose}>
      <MenuItem
        data-testid="rename-customer"
        onClick={(event) => {
          event.stopPropagation();
          handleClickOpenRename();
        }}
        disabled={!canRename}
      >
        <ListItemIcon>
          <RenameIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Rename</ListItemText>
      </MenuItem>
      <MenuItem
        data-testid="delete-customer"
        onClick={(event) => {
          event.stopPropagation();
          handleClickOpenDelete();
        }}
        disabled={!canDelete}
      >
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
      <MenuItem
        data-testid="manage-connectors"
        onClick={(event) => {
          event.stopPropagation();
          navigate(`/Connectors/${customer.id}`);
        }}
        disabled={!canDelete}
      >
        <ListItemIcon>
          <ConnectorIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Connectors</ListItemText>
      </MenuItem>
    </Menu>
  );
};

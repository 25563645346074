import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { KaeplaEvent } from '@kaepla/types';

import { KaeplaEventGroup } from './EventGroups.js';

const getDay = (dateTime: Timestamp) => {
  return DateTime.fromMillis(dateTime.toMillis()).toFormat('yyyy-MM-dd');
};

export const groupEvents = (events: KaeplaEvent[]) => {
  let count = 0;
  let asOf = '-';
  let previousDay: string;
  let previous: KaeplaEvent;
  const eventBundles: Record<string, KaeplaEventGroup> = {};
  let eventGroups: KaeplaEvent[] = [];
  //   console.log(
  //     events.map((e) => ({
  //       uid: e.uid,
  //       v: e.eventType,
  //       t: e.dateTime.toDate().toLocaleString(),
  //     })),
  //   );
  events.forEach((event, index) => {
    if (event.uid) {
      previous = events[index - 1];
      if (previous) {
        previousDay = getDay(previous.dateTime);
      }
      if (
        previous?.uid &&
        (previous.uid !== event.uid || previousDay !== getDay(event.dateTime) || count > 20)
      ) {
        asOf = `${previous.uid}-${previousDay}-${previous.dateTime.toMillis()}`;
        eventBundles[asOf] = {
          uid: previous.uid,
          lastEvent: eventGroups[0],
          events: [...eventGroups],
        };
        eventGroups = [];
        count = 0;
      }
      eventGroups.push(event);
      count++;
    }
  });

  if (eventGroups.length > 0) {
    const first = eventGroups[0];
    if (first.uid) {
      asOf = `${first.uid}--${first.dateTime.toMillis()}`;
      eventBundles[asOf] = {
        uid: first.uid,
        lastEvent: first,
        events: [...eventGroups],
      };
    }
  }

  return eventBundles;
};

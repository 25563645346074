import DeleteIcon from '@mui/icons-material/DeleteOutline';
import RenameIcon from '@mui/icons-material/EditOutlined';
import ConnectIcon from '@mui/icons-material/LinkOutlined';
import JoinIcon from '@mui/icons-material/PersonAddOutlined';
import SetupAndResetIcon from '@mui/icons-material/RestartAltOutlined';
import {
  default as ConfigureIcon,
  default as SetupIcon,
} from '@mui/icons-material/SettingsOutlined';
import ImportIcon from '@mui/icons-material/SyncOutlined';
import { Menu } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  FirestoreTimestamp,
  KaeplaDataOperation,
  KaeplaEventEffect,
  KaeplaEventType,
  KaeplaFunctionGroup,
  KaeplaPerspective,
  KaeplaProject,
  MatrixSimulationYears,
} from '@kaepla/types';

import { useAuth } from '../../../../AuthReactProvider.js';
import { useUserPreferences } from '../../../../UserPreferencesProvider.js';
import { fireBaseDeleteField } from '../../../../services/firestore/_firestoreShorthands.js';
import { createEvent } from '../../../../services/firestore/createEvent';
import { createProjectAssignmentForEmail } from '../../../../services/firestore/createProjectAssignmentForEmail.js';
import { takeOverProject } from '../../../../services/firestore/takeOverProject.js';
import { updateProject } from '../../../../services/firestore/updateProject.js';
import { dataGridSettings } from '../../../../services/recoil/nonpersistent/dataGridSets.js';
import { perspectiveState } from '../../../../services/recoil/nonpersistent/perspectiveState.js';
import { perspectivesState } from '../../../../services/recoil/nonpersistent/perspectivesState.js';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState.js';
import { simulationState } from '../../../../services/recoil/nonpersistent/simulationState';
import { snapShotState } from '../../../../services/recoil/nonpersistent/snapshotState.js';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState.js';
import { GenericConfirmDialog } from '../../../Layout/features/GenericDeleteConfirmDialog';

import { ProjectConfigureDialog } from './ProjectConfigureDialog.js';
import { ProjectConnectDialog } from './ProjectConnectDialog.js';
import { ProjectDeleteDialog } from './ProjectDeleteDialog.js';
import { ProjectRenameDialog } from './ProjectRenameDialog.js';

interface Options {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  handleGenerateDummyData: () => void;
  project: KaeplaProject;
}
export const CardMenu = ({
  anchorEl,
  open,
  onClose,
  handleGenerateDummyData,
  project,
}: Options) => {
  const navigate = useNavigate();
  const { setPreferences } = useUserPreferences();
  const setProject = useSetRecoilState(projectState);
  const setCurrentScopePath = useSetRecoilState(currentScopePathState);
  const resetSimulation = useResetRecoilState(simulationState);
  const resetSnapshot = useResetRecoilState(snapShotState);
  const resetPerspective = useResetRecoilState(perspectiveState);
  const resetPerspectives = useResetRecoilState(perspectivesState);
  const resetGridSets = useResetRecoilState(dataGridSettings);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const { kaeplaUser } = useAuth();

  const [openProjectRename, setOpenProjectRename] = useState(false);
  const [openProjectDelete, setOpenProjectDelete] = useState(false);
  const [openProjectConnect, setOpenProjectConnect] = useState(false);
  const [openProjectConfigure, setOpenProjectConfigure] = useState(false);
  const [openProjectReset, setOpenProjectReset] = useState(false);

  const handleCloseRenameDialog = () => {
    setOpenProjectRename(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenProjectDelete(false);
  };

  const handleCloseConnectDialog = () => {
    setOpenProjectConnect(false);
  };

  const handleCloseConfigureDialog = () => {
    setOpenProjectConfigure(false);
  };

  const resetAndSetup = () => {
    setCurrentScopePath([]);
    /**
     * TODO: refactor all firestore updateDoc calls to handle undefined
     * an approach would be to map all possible undefined options properties from the type to fireBaseDeleteField()
     * AFTER casting the data to DocumentData from firestore which has any for all props
     */
    const newProject = { ...project, id: project.id } as KaeplaProject;
    delete newProject.initializedAt;
    delete newProject.defaultPerspective;
    delete newProject.simulationYears;
    delete newProject.totalDimensionsCount;
    delete newProject.totalRecordsCount;
    setProject(newProject);
    resetSimulation(); // in case we had  one selected - simulations are removed upon reset by server trigger
    resetSnapshot(); // this is to reset the snapshot state
    resetPerspective(); // this as well
    resetPerspectives(); // those as well
    resetGridSets(); // and those
    setPreferences({ lastPerspectiveId: 'default' });
    const projectUpdate = { ...newProject, id: project.id } as KaeplaProject;
    // the deleted initializedAt indicates to clean up simulations, targets, etc. in the updateProject trigger
    projectUpdate.initializedAt = fireBaseDeleteField() as FirestoreTimestamp;
    projectUpdate.defaultPerspective = fireBaseDeleteField() as unknown as KaeplaPerspective;
    projectUpdate.simulationYears = fireBaseDeleteField() as unknown as MatrixSimulationYears;
    projectUpdate.totalDimensionsCount = fireBaseDeleteField() as unknown as number;
    projectUpdate.totalRecordsCount = fireBaseDeleteField() as unknown as number;
    void updateProject({ project: projectUpdate });

    // let's log this event
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PROJECT_RESET_PROJECT,
      effect: KaeplaEventEffect.PROJECT_RESET,
      functionGroup: KaeplaFunctionGroup.PROJECTS,
      operation: KaeplaDataOperation.CREATE,
      project,
      scopePath: [],
    });

    navigate(`/Project/${project.id}`);
  };

  const canManageProject =
    kaeplaAssignment?.devTeamMember ?? (project.ownedBy ?? project.createdBy) === kaeplaUser?.uid;

  if (!open) return null;

  return (
    <>
      <Menu anchorEl={anchorEl} open onClose={onClose}>
        {canManageProject && (
          <MenuItem
            data-testid="project-rename"
            onClick={(event) => {
              event.stopPropagation();
              setOpenProjectRename(true);
            }}
          >
            <ListItemIcon>
              <RenameIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Rename</ListItemText>
          </MenuItem>
        )}

        {!project.importConfigurationId && canManageProject && (
          <MenuItem
            data-testid="project-generate-dummy-data"
            onClick={(event) => {
              event.stopPropagation();
              handleGenerateDummyData();
            }}
          >
            <ListItemIcon>
              <SetupIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Generate Dummy Data</ListItemText>
          </MenuItem>
        )}
        {project.importConfigurationId && (
          <MenuItem
            data-testid="project-generate-dummy-data"
            onClick={(event) => {
              event.stopPropagation();
              setProject(project);
              navigate(`/SyncData/${project.id}`);
            }}
          >
            <ListItemIcon>
              <ImportIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Import & Update</ListItemText>
          </MenuItem>
        )}

        {canManageProject && (
          <MenuItem
            data-testid="project-reset"
            onClick={(event) => {
              event.stopPropagation();
              if (project.initializedAt) {
                setOpenProjectReset(true);
                return;
              }
              void resetAndSetup();
            }}
          >
            <ListItemIcon>
              <SetupAndResetIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{project.initializedAt ? 'Reset' : 'Setup'}</ListItemText>
            <GenericConfirmDialog
              openDelete={openProjectReset}
              title="Reset Project?"
              description="This action can not be reverted. All created perspectives, filters, simulations, project team member assignments and targets will be lost."
              confirm="reset"
              handleCloseDelete={() => {
                setOpenProjectReset(false);
              }}
              processDelete={() => {
                void resetAndSetup();
              }}
            />
          </MenuItem>
        )}

        {canManageProject && (
          <MenuItem
            data-testid="project-connect"
            onClick={(event) => {
              event.stopPropagation();
              setOpenProjectConnect(true);
            }}
          >
            <ListItemIcon>
              <ConnectIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Connect</ListItemText>
          </MenuItem>
        )}

        {canManageProject && (
          <MenuItem
            data-testid="project-configure"
            disabled={!project.connectorId}
            onClick={(event) => {
              event.stopPropagation();
              setOpenProjectConfigure(true);
            }}
          >
            <ListItemIcon>
              <ConfigureIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              Configure {!project.connectorId && '(select connector first)'}
            </ListItemText>
          </MenuItem>
        )}

        {canManageProject && (
          <MenuItem
            data-testid="project-takeover"
            onClick={(event) => {
              if (!kaeplaUser) return;
              event.stopPropagation();
              void takeOverProject({ project, uid: kaeplaUser.uid });
            }}
          >
            <ListItemText>Take Over</ListItemText>
          </MenuItem>
        )}

        {canManageProject && <Divider />}

        {canManageProject && (
          <MenuItem
            data-testid="project-delete"
            onClick={(event) => {
              event.stopPropagation();
              setOpenProjectDelete(true);
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        )}
        {kaeplaAssignment !== undefined && (
          <MenuItem
            data-testid="project-invite-myself-admin"
            onClick={(event) => {
              event.stopPropagation();

              if (!kaeplaUser) return;
              void createProjectAssignmentForEmail({
                project,
                email: kaeplaUser.email,
                scopePath: [],
                assignedBy: kaeplaUser.uid,
                complete: true,
              });
            }}
          >
            <ListItemIcon>
              <JoinIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Invite myself (Admin)</ListItemText>
          </MenuItem>
        )}
      </Menu>
      {/* TODO: make the two below a GenericConfirmDialog ? */}
      <ProjectRenameDialog
        open={openProjectRename}
        handleClose={handleCloseRenameDialog}
        handleMenuClose={onClose}
        project={project}
      />
      <ProjectDeleteDialog
        open={openProjectDelete}
        handleClose={handleCloseDeleteDialog}
        handleMenuClose={onClose}
        project={project}
      />
      <ProjectConnectDialog
        open={openProjectConnect}
        project={project}
        handleClose={handleCloseConnectDialog}
        handleMenuClose={onClose}
      />
      <ProjectConfigureDialog
        open={openProjectConfigure}
        project={project}
        handleClose={handleCloseConfigureDialog}
        handleMenuClose={onClose}
      />
    </>
  );
};

import { KaeplaDataOperation, KaeplaEventType, KaeplaFunctionGroup } from '@kaepla/types';
import AddIcon from '@mui/icons-material/Add';
import {
  Divider,
  Fab,
  FormControlLabel,
  Grid2 as Grid,
  Paper,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../AuthReactProvider.js';
import { useUserPreferences } from '../../../UserPreferencesProvider';
import { createEvent } from '../../../services/firestore/createEvent';
import { createNewPerspective } from '../../../services/firestore/createNewPerspective';
import { perspectiveFavoritesState } from '../../../services/recoil/nonpersistent/perspectiveFavoritesState';
import { perspectiveState } from '../../../services/recoil/nonpersistent/perspectiveState';
import { perspectivesState } from '../../../services/recoil/nonpersistent/perspectivesState';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { Layout } from '../../Layout/Layout';
import { ProjectLoading } from '../../features/ProjectLoading.js';

import { PerspectivesOverview } from './PerspectivesOverview.js';
import { ProjectInfoSmall } from './features/ProjectSummary/features/ProjectInfoSmall.js';
import { updatePerspectiveFavorites } from './helpers/updatePerspectiveFavorites.js';

export const Perspectives = () => {
  const { kaeplaUser } = useAuth();
  const { setPreferences, getPreferences } = useUserPreferences();
  const navigate = useNavigate();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const setPerspective = useSetRecoilState(perspectiveState);
  const setPerspectives = useSetRecoilState(perspectivesState);
  const [perspectiveFavorites, setPerspectiveFavorites] = useRecoilState(perspectiveFavoritesState);
  const [showAllPerspectivesForProject, setShowAllPerspectivesForProject] = useState(false);

  const handleShowAllPerspectivesForProject = () => {
    if (!kaeplaUser?.uid) return;
    setShowAllPerspectivesForProject(!showAllPerspectivesForProject);
    setPreferences({
      showAllPerspectives: !showAllPerspectivesForProject,
    });
  };

  const create = async () => {
    if (!kaeplaUser || !currentScopePath) return;
    const _perspective = await createNewPerspective({
      project,
      createdBy: kaeplaUser.uid,
      scopePath: currentScopePath,
      perspective: project.defaultPerspective,
    });

    setPerspective(_perspective);
    setPreferences({ lastPerspectiveId: _perspective.id });

    updatePerspectiveFavorites({
      uid: kaeplaUser.uid,
      project,
      perspective: _perspective,
      currentScopePath,
      perspectiveFavorites,
      setPerspectiveFavorites,
      isFav: true,
    });

    setPerspectives((perspectives) => [...perspectives, _perspective]);

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PERSPECTIVES_CREATE_PERSPECTIVE,
      functionGroup: KaeplaFunctionGroup.PERSPECTIVES,
      operation: KaeplaDataOperation.READ,
      project,
      scopePath: _perspective.scopePath,
    });

    navigate(`/Perspective/${project.id}`);
  };

  useEffect(() => {
    const _preferences = getPreferences();
    if (_preferences) {
      setShowAllPerspectivesForProject(!!_preferences.showAllPerspectives);
    }
  }, [getPreferences]);

  useEffect(() => {
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PERSPECTIVES_ENTER_SIMULATIONS_OVERVIEW,
      functionGroup: KaeplaFunctionGroup.PERSPECTIVES,
      operation: KaeplaDataOperation.READ,
      scopePath: currentScopePath,
      project,
    });
  }, [currentScopePath, kaeplaUser?.uid, project]);

  if (!project?.id) {
    return <ProjectLoading />;
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 4, md: 3 }}>
              <ProjectInfoSmall title={project.name} />
            </Grid>
            <Grid size={2}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid
              size={{ xs: 12, sm: 6, md: 7 }}
              sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
            >
              <Typography variant="h6">Dashboards</Typography>
              <Typography variant="body1">
                Dashboards allow you to quickly aggregate and visualize key figures for your
                planning project. They are automatically shared with every member of your project's
                scope, you can see them by switching on "show other dashboards".
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Toolbar disableGutters variant="dense">
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <FormControlLabel
            control={
              <Switch
                checked={showAllPerspectivesForProject}
                size="small"
                onChange={handleShowAllPerspectivesForProject}
              />
            }
            label={`${showAllPerspectivesForProject ? 'hide' : 'show'} other dashboards`}
          />
        </Toolbar>
        <Fab
          color="primary"
          aria-label="add simulation"
          data-testid="simulation-add"
          sx={{ position: 'fixed', bottom: 20, right: 20 }}
          disabled={!!project.currentImportId}
          onClick={() => {
            void create();
          }}
        >
          <AddIcon />
        </Fab>
        <PerspectivesOverview showAllPerspectivesForProject={showAllPerspectivesForProject} />
      </>
    </Layout>
  );
};
